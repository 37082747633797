import { Grid } from '@material-ui/core';
import { AddRiskFormField } from 'modules';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

// A component that represents an array of key/value pairs
const ValueSet = ({ field, child = null, prefix, formProps }) => {
  const [optionsToPopulate, setOptionsToPopulate] = useState([]);

  const mirrorValues = field.mirror ? useWatch({ name: `${prefix}${field.mirror}`, control: formProps?.control }) : null;

  useEffect(() => {
    if (mirrorValues) {
      const options = field.options?.filter((option) => mirrorValues?.includes(option?.value));
      setOptionsToPopulate(options);
    } else if (!field.mirror) {
      const prePopulatedFields = field.options?.filter((option) => option.prePopulate);
      setOptionsToPopulate(prePopulatedFields);
    }
  }, [mirrorValues]);

  useEffect(() => {
    optionsToPopulate.forEach((option, index) => formProps.setValue(`${prefix}${field.name}[${index}].key`, option?.value));
  }, [optionsToPopulate]);

  const valueType = field.arrayItemDef.find((def) => def.name === 'value')?.type;

  return (
    <>
      {optionsToPopulate?.map((option, index) => (
        <Grid item xs={6} sm={6} md={4} key={`visible-${option.value}`}>
          <AddRiskFormField
            key={option.value}
            field={{
              type: valueType,
              label: option.label,
              child,
              name: `${prefix}${field.name}[${index}].value`,
            }}
            formProps={formProps}
          />
        </Grid>
      ))}
    </>
  );
};

export default ValueSet;
