'react-redux';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

// app
import styles from './PolicyCard.style';
import { Avatar, FormGrid, Restricted } from 'components';
import { QuoteLine, PolicyCardSkeleton } from './PolicyCard';
import { useGetQuotes, QB_RISK_QUOTES } from 'lib/quoteBind';
import { RISK_STATUS_CANCELLED } from 'consts';

import { makeStyles, withStyles, Box, Card, CardHeader, CardContent, Typography, LinearProgress, Divider } from '@material-ui/core';

import HowToVoteIcon from '@material-ui/icons/HowToVote';

import * as utils from 'utils';
import { ROLE_BROKER, ROLE_PRODUCER } from 'consts';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
  },
  bar: {
    backgroundColor: theme.palette.status.cancelled,
  },
}))(LinearProgress);

export const RiskPolicyCancelled = ({ risk, riskIsLoading }) => {
  const classes = makeStyles(styles, { name: 'RiskPolicyCancelled' })();

  const { data: quotes, isLoading } = useGetQuotes(QB_RISK_QUOTES, risk?.id, {
    enabled: risk?.id ? true : false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const policy = quotes?.find((q) => q.status === RISK_STATUS_CANCELLED) || null;
  const hasSurplusLineTax = risk?.hasSurplusLineTax ?? false;

  if (isLoading || riskIsLoading || !policy) {
    return (
      <FormGrid item xs={12} sm={6} md={6} lg={4}>
        <PolicyCardSkeleton />
      </FormGrid>
    );
  }

  const carrierName = get(policy, 'carrierName');
  const quotePremium = isNumber(policy?.commission?.revisedPremium) ? policy.commission.revisedPremium : policy.premium;

  const policyResponse = policy?.response;
  const policyNumber =
    policy.facility.brokerCode && policy.declarationNumber ? `${policy.facility.brokerCode}${policy.declarationNumber}` : '';

  const premiumValue = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t('format.number', {
    value: { number: quotePremium, default: '-' },
  })}`;

  const surplusLineTax = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t('format.number', {
    value: { number: policy?.quoteValues?.surplusLineTax, default: '-' },
  })}`;

  const subheader = <Box style={{ display: 'flex', alignItems: 'center' }}>{carrierName}</Box>;

  return (
    <FormGrid item xs={12} sm={6} md={6} lg={4} data-testid="RiskPolicyCancelled">
      <Card classes={{ root: classes.card }}>
        <CardHeader
          avatar={<Avatar icon={HowToVoteIcon} size={32} border />}
          title={
            <Typography variant="h3" className={classes.cardFacility} style={{ textAlign: 'left' }}>
              {`${policyNumber}`}
            </Typography>
          }
          subheader={subheader}
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <Box pb={2}>
            <BorderLinearProgress variant="determinate" value={100} />
          </Box>
          <Box p={2}>
            <FormGrid container spacing={1} alignItems="center">
              <QuoteLine title={utils.string.t('app.premium')} value={premiumValue} titleVariant="h5" />
              {hasSurplusLineTax ? (
                <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                  <QuoteLine title={utils.string.t('risks.surplusLineTax')} value={surplusLineTax} />
                </Restricted>
              ) : null}
              <QuoteLine
                title={utils.string.t('risks.effectiveFrom')}
                value={utils.string.t('format.date', {
                  value: { date: policyResponse.effectiveFrom, format: 'll', default: '-' },
                })}
              />
              <QuoteLine
                title={utils.string.t('risks.effectiveTo')}
                value={utils.string.t('format.date', {
                  value: { date: policyResponse.effectiveTo, format: 'll', default: '-' },
                })}
              />
              <FormGrid item xs={12}>
                <Divider />
              </FormGrid>
              <QuoteLine
                title={utils.string.t('risks.cancelledDate')}
                value={utils.string.t('format.date', {
                  value: { date: policy.cancelledDate, format: 'll', default: '-' },
                })}
              />
              <QuoteLine title={utils.string.t('risks.cancelledBy')} value={policy.cancelledBy.userName || '-'} />
            </FormGrid>
          </Box>
        </CardContent>
      </Card>
    </FormGrid>
  );
};
