import { useState } from 'react';

// Mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Card, CardContent, Paper, Divider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FeedbackIcon from '@material-ui/icons/Feedback';

// app
import { Tooltip } from 'components';
import { useGetRiskDefinitions, QB_RISK_DEFINITION } from 'lib/quoteBind';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  conflictValueExtended: {
    maxWidth: 'none',
  },
}));

const getOptionLabel = (options) => (value) => {
  const option = options?.find((o) => String(o.value) === String(value)) || {};
  return option?.label !== 'Select...' ? option.label : '';
};

// TODO: move to utils
export const renderValue = (field, value, valuesByID = []) => {
  const prefix = '';
  let suffix = '';
  let newValue = value;

  switch (field.type?.toUpperCase()) {
    case 'DOUBLE':
      const isPercent = field && field.validation && field.validation.percent;
      newValue = utils.string.t(`format.${isPercent ? 'percent' : 'currency'}`, { value: { number: value } });
      break;
    case 'NUMBER':
      newValue = utils.string.t(`format.number`, { value: { number: value } });
      break;
    case 'BOOLEAN':
    case 'TOGGLE':
      newValue = utils.risk.checkBoolean(value);
      break;

    case 'CHECKBOX':
      newValue = utils.risk.checkBoolean(value);
      break;
    case 'DATE':
    case 'DATEPICKER':
      newValue = utils.string.t(`format.date`, { value: { date: value } });
      break;
    case 'TIME':
    case 'TIMEPICKER':
      newValue = typeof value === 'string' ? value : utils.date.formatToTime(value);
      break;
    case 'ID':
      newValue = valuesByID[field.name]?.id === value ? valuesByID[field.name]?.name : '';
      break;
    case 'AUTOCOMPLETEMUI':
    case 'AUTOCOMPLETEMUIASYNC':
      if (utils.generic.isValidArray(value)) {
        newValue = '';
        for (const singleValue of value) {
          newValue += `${singleValue?.label || singleValue?.name || singleValue},`;
        }
        newValue = newValue.slice(0, -1);
      } else {
        newValue = getOptionLabel(options)(value);
      }

      break;
    case 'SELECTASYNC':
    case 'SELECT': {
      const options = utils.generic.isValidArray(field.options, true) ? field.options : [];

      newValue = utils.generic.isValidArray(value, true) ? value.map(getOptionLabel(options)).join(', ') : getOptionLabel(options)(value);

      break;
    }
    case 'ARRAY': {
      if (utils.generic.isValidArray(value)) {
        newValue = value?.map((item, index) => {
          const isLastValue = index === value.length - 1;

          const filteredItem = Object.entries(item).filter(([_, v]) => v);

          const mappedItem = filteredItem.map(([key, v], itemIndex) => {
            const def = field.arrayItemDef.find(({ name }) => name === key);
            const isLastItem = itemIndex === filteredItem.length - 1;
            const valueLabel = field.options?.find(({ value }) => value === v)?.label || v;
            return (
              <Fragment key={key}>
                {renderValue(def, valueLabel)}
                {!isLastItem ? ' - ' : ''}
                {isLastItem && !isLastValue ? '; ' : ''}
              </Fragment>
            );
          });

          return mappedItem;
        });
      } else if (value) {
        newValue = Object.entries(value)
          .filter(([_, v]) => v)
          .map(([key, v]) => {
            const label = field.options?.find(({ value }) => value === key)?.label;

            return `${label}: ${v}`;
          })
          .join(';\n');
      }

      break;
    }

    case 'RADIO': {
      newValue = value || '';
      break;
    }

    default:
      break;
  }

  // add prefix/suffix for specific fields
  if (field.name === 'distanceToCoast' && value) {
    suffix = ` ${utils.string.t('map.unit.miles')}`;
  }
  // the extra <span /> is used to prevent Material-UI complaining about not receiving a ReactNode
  // this happens if the value is true/false/undefined/null...
  // this workaround prevents errors in case some invalid values fall through the cracks
  return utils.generic.isValidObject(newValue) ? null : (
    <span>
      {prefix}
      {newValue}
      {suffix}
    </span>
  );
};

export const Conflict = ({ conflict, riskType }) => {
  const [checked, setChecked] = useState([false, false]);
  const [selectedValue, setSelectedValue] = useState(null);

  // TODO: temporary solution for release 0.0.1
  const hideCheckRadioButton = true;

  const { data: riskDefinitionData, isLoading: riskDefinitionsLoading } = useGetRiskDefinitions(QB_RISK_DEFINITION, riskType);

  const {
    availableResolutionTypes,
    leftSideChangeType,
    leftSideChanges,
    leftSideValue,
    rightSideChangeType,
    rightSideChanges,
    rightSideValue,
    message,
  } = conflict;
  if (riskDefinitionsLoading) return null;

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange = (event) => {
    setChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      newChecked[event] = !newChecked[event];
      return newChecked;
    });
  };

  const riskDefinitionsFieldsByType = riskDefinitionData?.data?.product || [];
  const definitionsFields = riskDefinitionsFieldsByType.filter((definition) => definition.type !== 'LABEL') || [];
  const isMultipleChoice = availableResolutionTypes.includes('RETAIN');

  return (
    <CardContent
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" flex="1">
          <Box
            style={{
              width: '50%',
              borderRight: '1px solid #ccc',
              padding: '10px 0px',
            }}
          >
            <Box p={1} display="flex" alignItems="start">
              {hideCheckRadioButton ? null : (
                <>
                  {isMultipleChoice ? (
                    <Checkbox
                      checked={checked[0]}
                      color="primary"
                      onChange={() => handleChange(0)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      style={{
                        padding: '0 10px 0 0',
                      }}
                    />
                  ) : (
                    <Radio
                      checked={selectedValue === 'left'}
                      color="primary"
                      onChange={handleRadioChange}
                      value="left"
                      name="radio-button"
                      style={{
                        padding: '0 10px 0 0',
                      }}
                    />
                  )}
                </>
              )}
              <ConflictValue
                value={leftSideValue}
                definition={definitionsFields}
                changes={leftSideChanges}
                type={leftSideChangeType}
                message={message}
              />
            </Box>
          </Box>
          <Box
            style={{
              width: '50%',
              padding: '10px 0 10px 20px ',
            }}
          >
            <Box p={1} display="flex" alignItems="start">
              {hideCheckRadioButton ? null : (
                <>
                  {isMultipleChoice ? (
                    <Checkbox
                      checked={checked[1]}
                      color="primary"
                      onChange={() => handleChange(1)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      style={{
                        padding: '0 10px 0 0',
                      }}
                    />
                  ) : (
                    <Radio
                      checked={selectedValue === 'right'}
                      color="primary"
                      onChange={handleRadioChange}
                      value="right"
                      name="radio-button-right"
                      style={{
                        padding: '0 10px 0 0',
                      }}
                    />
                  )}
                </>
              )}
              <ConflictValue
                value={rightSideValue}
                definition={definitionsFields}
                changes={rightSideChanges}
                type={rightSideChangeType}
                message={message}
              />
              {message ? (
                <Tooltip title={message} rich>
                  <FeedbackIcon style={{ color: '#334762', fontSize: 20, marginLeft: 8 }} />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
};

const getFieldFromDefinition = (definition, key) => {
  return definition
    .flatMap((field) => {
      if (field.type === 'OBJECT') {
        return field.objectDef;
      }
      if (field.type === 'ARRAY') {
        return field.arrayItemDef;
      } else return field;
    })
    ?.find((field) => field.name === key);
};

const ConflictHeader = ({ title, date, dateFormat = 'DD MMM YYYY HH:mm' }) => {
  return (
    <>
      <Typography
        variant="h6"
        style={{
          fontSize: 14,
          color: '#fff',
          fontWeight: 600,
          marginBottom: 0,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        style={{
          fontSize: 13,
          color: '#fff',
          fontWeight: 600,
        }}
      >
        {utils.date.formatDateWithParameter(date, dateFormat)}
      </Typography>
    </>
  );
};

const ConflictValueExtended = ({ value, definition, changes, type }) => {
  const isRemoved = type === 'REMOVE';

  return (
    <Box position="relative">
      {isRemoved ? (
        <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
          <RemoveCircleIcon fontSize="small" style={{ color: '#FF0000', marginRight: 10 }} />
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              fontWeight: 600,
              margin: 0,
            }}
          >
            Removed
          </Typography>
        </Box>
      ) : null}
      {Object.keys(value).map((key) => {
        const field = getFieldFromDefinition(definition, key);
        if (field?.name !== 'buildingTitle' && (field?.type === 'HIDDEN' || field?.type === 'LABEL')) return null;

        const changedValue = changes?.find((change) => change.fieldName === key) || null;

        return (
          <Box display="flex" flexWrap="wrap" alignItems="start" style={{ marginBottom: 4 }}>
            {field?.label ? (
              <Typography
                style={{
                  fontSize: 12,
                  color: 'black',
                  fontWeight: changedValue ? 700 : 500,
                  width: 'calc(50% - 20px)',
                  textAlign: 'left',
                  marginRight: 20,
                }}
              >
                {field.label}:&nbsp;
              </Typography>
            ) : null}
            <Typography
              style={{
                fontSize: 12,
                color: 'black',
                fontWeight: changedValue ? 700 : 600,
                display: 'flex',
                alignItems: 'center',
                lineHeight: '1.5',
                flex: 1,
                width: '50%',
                textAlign: 'left',
              }}
            >
              {renderValue(field, value[key], {
                valuesByID: {},
                countries: [],
              })}
              {changedValue ? (
                <>
                  <ArrowForwardIcon style={{ fontSize: 16, marginLeft: 10, marginRight: 10 }} />
                  {renderValue(field, changedValue?.value, {
                    valuesByID: {},
                    countries: [],
                  })}
                </>
              ) : null}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const ConflictValue = ({ value, definition, changes, type, availableResolutionTypes }) => {
  const classes = useStyles();
  const valueFields = Object.keys(value);
  const multipleFields = valueFields.length > 1;
  const isRemoved = type === 'REMOVE';

  return (
    <Box position="relative" style={{ width: '100%' }}>
      {valueFields.map((key, index) => {
        const field = getFieldFromDefinition(definition, key);
        if (field?.name !== 'buildingTitle' && (field?.type === 'HIDDEN' || field?.type === 'LABEL')) return null;

        const changedValue = changes?.find((change) => change.fieldName === key) || null;
        const isChanged = changes?.findIndex((change) => change.fieldName === key) > -1 || null;
        const isTitle = utils.risk.isTitleField(field);
        const showIcon = multipleFields && index === 0;

        return isChanged || isTitle ? (
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            {field?.label ? (
              <Typography
                style={{
                  fontSize: 12,
                  color: 'black',
                  fontWeight: 'normal',
                }}
              >
                {field.label}:
              </Typography>
            ) : null}
            <Box display="flex" flexWrap="wrap" alignItems="start" justifyContent="space-between">
              <Typography
                style={{
                  fontSize: 12,
                  color: 'black',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                {renderValue(field, value[key], {
                  valuesByID: {},
                  countries: [],
                })}
                {changedValue ? (
                  <>
                    <ArrowForwardIcon style={{ fontSize: 16, marginLeft: 10, marginRight: 10 }} />
                    {renderValue(field, changedValue?.value, {
                      valuesByID: {},
                      countries: [],
                    })}
                  </>
                ) : null}
              </Typography>
              {showIcon ? (
                <Tooltip
                  title={<ConflictValueExtended value={value} definition={definition} changes={changes} type={type} />}
                  rich
                  nestedClasses={{
                    tooltip: classes.conflictValueExtended,
                  }}
                >
                  <InfoIcon style={{ color: '#334762', fontSize: 20 }} />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        ) : null;
      })}
      {isRemoved ? (
        <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
          <RemoveCircleIcon fontSize="small" style={{ color: '#FF0000', marginRight: 10 }} />
          <Typography
            variant="h3"
            style={{
              fontSize: 18,
              fontWeight: 600,
              margin: 0,
            }}
          >
            Removed
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export const EndorsementConflictResolution = ({ conflicts, riskType }) => {
  const { leftSideEffectiveFrom, rightSideEffectiveFrom } = conflicts[0];

  return (
    <Card
      component={Paper}
      elevation={2}
      style={{
        margin: 20,
      }}
    >
      <CardContent
        style={{
          padding: 0,
          backgroundColor: '#334762',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="1">
            <Box
              style={{
                width: '50%',
                padding: 20,
                borderRight: '1px solid #8f9cb1',
              }}
            >
              <ConflictHeader title="Effective From" date={leftSideEffectiveFrom} />
            </Box>
            <Box
              style={{
                width: '50%',
                padding: 20,
              }}
            >
              <ConflictHeader title="Effective From" date={rightSideEffectiveFrom} />
            </Box>
          </Box>
        </Box>
      </CardContent>
      {conflicts.map((conflict) => (
        <>
          <Conflict key={conflict.id} conflict={conflict} riskType={riskType} />
          <Divider />
        </>
      ))}
    </Card>
  );
};
