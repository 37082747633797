import { useState } from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PostAddIcon from '@material-ui/icons/PostAdd';

import { Avatar, Button, FormGrid, Tooltip } from 'components';
import { EndorsementComparison } from 'components/EndorsementComparison';

import { ENDORSEMENT_CHANGE_TYPE } from 'consts/quote-bind';
import { useDownloadEndorsement } from 'lib/quoteBind/';
import * as utils from 'utils';

import { QuoteLine } from '../PolicyCard';
// Edge
import styles from '../PolicyCard.style';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#000!important',
    margin: '0px',
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#000!important',
  },
  buttonCancel: {
    color: 'white',
    backgroundColor: theme.palette.status.cancelled,
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: theme.palette.status.cancelled,
      color: 'white',
    },
  },
}));

const Accordion = withStyles(() => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      backgroundColor: '#fff',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

export const Endorsements = ({ title, endorsements, policyCurrency, riskType, testid, displayIndex = false, canCancel = false }) => {
  const classes = useStyles();
  const styleClasses = makeStyles(styles, { name: 'PolicyCard' })();
  const [expandedEndorsement, setExpandedEndorsement] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const { mutateAsync: downloadEndorsement } = useDownloadEndorsement();

  const handleDownloadEndorsement = async (id) => {
    setIsDownloading(true);
    await downloadEndorsement({ id });
    setIsDownloading(false);
  };

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpandedEndorsement(isExpanded ? panel : false);
  };

  return (
    <Card classes={{ root: styleClasses.card }} data-testid={testid}>
      <CardHeader
        avatar={<Avatar icon={PostAddIcon} size={32} border />}
        title={
          <Typography variant="h3" className={styleClasses.cardFacility} style={{ textAlign: 'left' }}>
            {title}
          </Typography>
        }
      />
      <CardContent classes={{ root: styleClasses.cardContent }}>
        {endorsements.map((endorsement) => {
          const { hasTemplates, premium, proRata } = endorsement;

          const premiumValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
            value: { number: premium, default: '-' },
          })}`;

          const proRataValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
            value: { number: proRata, default: '-' },
          })}`;

          const additionalPremiumTotal = endorsement?.additionalPremiums?.reduce((acc, curr) => acc + curr.premium, 0);
          const additionalPremiumValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
            value: { number: additionalPremiumTotal, default: '-' },
          })}`;

          const boundOn = utils.string.t('format.date', {
            value: { date: endorsement?.response?.responseDate, format: 'll HH:mm', default: '-' },
          });
          const postPatchData = { ...endorsement?.postPatchRisk, comments: endorsement?.comments };
          return (
            <Accordion
              key={endorsement.id}
              expanded={expandedEndorsement === endorsement.id}
              onChange={handleChange(endorsement.id)}
              data-testid="endorsement-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="endorsement-content" id="endorsement-header" role="button">
                <Box display="flex" flexDirection="row" flex="1" alignItems="center" justifyContent="space-between">
                  <Box display="flex" flexDirection="row" flex="1" alignItems="center">
                    {displayIndex ? (
                      <Tooltip title={`${utils.string.t('app.endorsementBoundOn')}: ${boundOn}`}>
                        <Box as="span" className={styleClasses.quotedIndex}>
                          {endorsement?.index}
                        </Box>
                      </Tooltip>
                    ) : null}
                    <Typography classes={{ root: classes.heading }} variant="h6" style={{ width: '12ch' }}>
                      {utils.string.t('format.date', {
                        value: { date: endorsement.effectiveFrom, format: 'll', default: '-' },
                      })}
                    </Typography>
                    {endorsement.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION ||
                    endorsement.changeType === ENDORSEMENT_CHANGE_TYPE.EXTENSION ? (
                      <Typography classes={{ root: classes.heading }} variant="h6">
                        -{' '}
                        {endorsement?.cancellationReason && endorsement.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION
                          ? endorsement?.cancellationReason
                          : utils.string.t(`risks.${endorsement.changeType}`)}
                      </Typography>
                    ) : (
                      <Typography classes={{ root: classes.heading }} variant="h6">
                        - {proRataValue}
                      </Typography>
                    )}
                  </Box>
                  <EndorsementComparison
                    prePatchRisk={endorsement?.prePatchRisk}
                    postPatchRisk={postPatchData}
                    effectiveFrom={endorsement?.effectiveFrom}
                    riskType={riskType}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="column" flex="1">
                  <Box p={2}>
                    <FormGrid container spacing={1} alignItems="center">
                      <QuoteLine title={utils.string.t('app.premium')} titleVariant="h5" />
                      <QuoteLine title={utils.string.t('app.fullTerm')} value={premiumValue} titleVariant="h5" indent />
                      <QuoteLine title={utils.string.t('app.additional')} value={additionalPremiumValue} titleVariant="h5" indent />
                      <QuoteLine title={utils.string.t('app.proRata')} value={proRataValue} titleVariant="h5" indent />
                      <QuoteLine
                        title={utils.string.t('risks.effectiveFrom')}
                        value={utils.string.t('format.date', {
                          value: { date: endorsement.effectiveFrom, format: 'll', default: '-' },
                        })}
                      />
                    </FormGrid>
                    {endorsement?.comments ? (
                      <Typography classes={{ root: classes.description }} variant="body1">
                        {endorsement.comments}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box
                    display="flex"
                    p={2}
                    justifyContent="space-between"
                    style={{
                      marginTop: 10,
                      backgroundColor: '#f5f5f5',
                    }}
                  >
                    {hasTemplates ? (
                      <Box mb={1} position="relative">
                        <Button
                          size="xsmall"
                          icon={PictureAsPdfIcon}
                          color="secondary"
                          text={utils.string.t('risks.downloadEndorsement')}
                          nestedClasses={{ icon: classes.icon }}
                          onClick={() => handleDownloadEndorsement(endorsement.id)}
                          disabled={isDownloading}
                        />
                        {isDownloading ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
                      </Box>
                    ) : null}
                    {canCancel ? (
                      <Box mb={1} position="relative">
                        <Button
                          size="xsmall"
                          icon={BlockIcon}
                          text={utils.string.t('risks.cancelEndorsement')}
                          nestedClasses={{ btn: classes.buttonCancel }}
                          onClick={() => {}}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </CardContent>
    </Card>
  );
};
