import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getProducts(token) {
  const response = await api(token).get('products');
  return response.data;
}

export function useGetProducts(queryKey, params, options) {
  return useApi([queryKey], async (_, token) => await getProducts(token), {
    ...options,
    cacheTime: 1000 * 60 * 60 * 24,
  });
}

async function getRiskProductsWithReports(token) {
  const response = await api(token).get('products/reports');
  return response.data;
}
export function useGetProductsWithReports(queryKey, params, options) {
  return useApi([queryKey, { ...params }], async (_, token) => await getRiskProductsWithReports(token), {
    ...options,
    cacheTime: 1000 * 60 * 60 * 24,
  });
}

// getRiskDefinitions
async function getRiskDefinitions(type, token) {
  if (!type) {
    throw new Error('useGetRiskDefinitions: Missing required parameters.');
  }

  const response = await api(token).get(`products/${type}`);

  return response.data;
}

export function useGetRiskDefinitions(queryKey, type, options) {
  return useApi([queryKey, type], async (_, token) => await getRiskDefinitions(type, token), {
    ...options,
  });
}
