// MUI
import { Box, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CompareIcon from '@material-ui/icons/Compare';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Button, FormGrid, Restricted } from 'components';

import { ROLE_BROKER, ROLE_PRODUCER } from 'consts';
import * as utils from 'utils';

import { useQuoteBindStyles } from '../QuoteBind.styles';

// app
import { QuoteCard } from './QuoteCard/QuoteCard';
import { QuoteCardSkeleton } from './QuoteCard/QuoteCardSkeleton';

import styles from './RiskQuotes.styles';

const RiskQuotesView = ({
  isLoading,
  riskIsLoading,
  quotes,
  riskIssues,
  riskClientCommission,
  handleBindRiskQuote,
  handleRiskRefresh,
  handleRequestToBind,
  handleReQuoteRisk,
  handleQuoteRefresh,
  handlePreBind,
  handleOpenCoverageComparison,
  showCoverageComparison,
  isQuoteLoading,
  hasSurplusLineTax,
  showCommissionControls,
}) => {
  const classes = makeStyles(styles, { name: 'RiskQuotes' })();
  const classesQB = useQuoteBindStyles();

  return (
    <Box className={classesQB.root}>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" className={classesQB.header}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="h3" className={classesQB.title}>
            {utils.string.t('app.quote_plural')}
          </Typography>
          {isQuoteLoading ? (
            <Box position="relative" className={classes.progressBox}>
              <CircularProgress size={24} classes={{ root: classes.circularProgress }} />
            </Box>
          ) : (
            <IconButton aria-label="Refresh" size="small" onClick={handleQuoteRefresh}>
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
        <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {isLoading || riskIsLoading ? null : (
              <>
                <Button
                  icon={AutorenewIcon}
                  size="xsmall"
                  variant="outlined"
                  color="primary"
                  text={utils.string.t('app.reQuote')}
                  onClick={() => handleReQuoteRisk(false)}
                  style={{ marginLeft: 5 }}
                />
                <Restricted include={[ROLE_BROKER]}>
                  {showCoverageComparison ? (
                    <Button
                      icon={CompareIcon}
                      size="xsmall"
                      variant="outlined"
                      color="primary"
                      text={utils.string.t('app.coverageComparison')}
                      onClick={() => handleOpenCoverageComparison()}
                      style={{ marginLeft: 5 }}
                    />
                  ) : null}
                </Restricted>
              </>
            )}
          </Box>
        </Restricted>
      </Box>
      <Box className={classesQB.content}>
        <FormGrid container spacing={2}>
          {isLoading || riskIsLoading ? (
            <FormGrid item xs={12} sm={6} md={4} lg={3}>
              <QuoteCardSkeleton />
            </FormGrid>
          ) : (
            quotes?.map((quote, index) => {
              const cardKey = `${index}${quote?.id}${quote?.facilityId}`;
              const issuesOnQuote = quote?.issues || [];
              const quoteIssues = [...issuesOnQuote, ...riskIssues] || [];
              const hasIssues = !!quoteIssues?.length;

              const quoteIssueData = { quoteIssues, hasIssues };

              return (
                <FormGrid item key={cardKey} xs={12} sm={6} md={4} lg={3}>
                  <QuoteCard
                    quote={quote}
                    riskIssues={riskIssues}
                    riskClientCommission={riskClientCommission}
                    issuesData={quoteIssueData}
                    handleBindRiskQuote={handleBindRiskQuote}
                    handleRiskRefresh={handleRiskRefresh}
                    handleRequestToBind={handleRequestToBind}
                    handlePreBind={handlePreBind}
                    hasSurplusLineTax={hasSurplusLineTax}
                    showCommissionControls={showCommissionControls}
                  />
                </FormGrid>
              );
            })
          )}
        </FormGrid>
      </Box>
    </Box>
  );
};

export default RiskQuotesView;
