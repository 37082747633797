import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// mui
import { makeStyles } from '@material-ui/core';

// app
import { Button, FormActions, FormContainer, FormDate, FormFields, FormSelect } from 'components';

import { useCancelPolicy, useExtendPolicy } from 'lib/quoteBind';
import { FLAT_CANCEL } from 'consts';
import * as utils from 'utils';

ExtendCancelPolicy.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  effectiveTo: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
  },
}));

export default function ExtendCancelPolicy({ handleClose, policyId, minDate, maxDate, isExtend, cancellationReasons, hint = null }) {
  const convertedMinDate = keepUTCDateInCurrentTimezone(minDate);
  const convertedMaxDate = keepUTCDateInCurrentTimezone(maxDate);

  const classes = useStyles();
  const { mutate: cancelPolicy, isLoading: cancelPolicyLoading } = useCancelPolicy();
  const { mutate: extendPolicy, isLoading: extendPolicyLoading } = useExtendPolicy();

  const isLoading = cancelPolicyLoading || extendPolicyLoading;
  const hasCancellationReasons = cancellationReasons?.length > 0 || false;

  const fields = useMemo(
    () => [
      {
        name: 'expiryDate',
        type: 'datepicker',
        value: convertedMinDate,
        validation: Yup.date()
          .nullable(utils.string.t(`risks.effectiveToInvalid`))
          .typeError(utils.string.t(`risks.effectiveToInvalid`))
          .required(utils.string.t(`risks.effectiveToInvalid`))
          .min(convertedMinDate, utils.string.t(isExtend ? `risks.effectiveToInvalidExtend` : `risks.effectiveToInvalidMin`))
          .max(convertedMaxDate, utils.string.t(isExtend ? `risks.effectiveToInvalidExtend` : `risks.effectiveToInvalidMax`)),
        label: 'Policy expiry date:',
        hint,
        muiComponentProps: {
          autoOk: true,
          minDate: convertedMinDate,
          maxDate: convertedMaxDate,
          placeholder: utils.date.today('DD-MM-yyyy'),
          format: 'DD-MM-yyyy',
          disableToolbar: true,
          fullWidth: true,
          autoComplete: 'off',
        },
      },
      ...(hasCancellationReasons
        ? [
            {
              name: 'cancellationReason',
              type: 'select',
              value: '',
              validation: Yup.string().required(utils.string.t(`risks.cancellationReasonInvalid`)),
              label: utils.string.t(`risks.cancellationReason`),
              options: cancellationReasons?.map((reason) => ({ value: reason, label: reason })) || [],
            },
          ]
        : []),
    ],
    [convertedMinDate, isExtend, convertedMaxDate, hint, hasCancellationReasons, cancellationReasons]
  );

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  const cancellationReason = useWatch({ control, name: 'cancellationReason' });
  const submitHandler = (data) => {
    const policyData = { policyId, expiryDate: cancellationReason === FLAT_CANCEL ? convertedMinDate : data.expiryDate };
    isExtend
      ? extendPolicy(policyData)
      : cancelPolicy({ ...policyData, cancellationReason: hasCancellationReasons ? data.cancellationReason : null });
  };

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(submitHandler)} data-testid="form-ExtendCancelPolicy">
        <FormFields type="dialog">
          {hasCancellationReasons ? (
            <FormSelect {...utils.form.getFieldProps(fields, 'cancellationReason')} control={control} error={errors.cancellationReason} />
          ) : null}
          {cancellationReason !== FLAT_CANCEL && (
            <FormDate {...utils.form.getFieldProps(fields, 'expiryDate')} control={control} error={errors.expiryDate} />
          )}
        </FormFields>

        <FormActions type="dialog">
          <Button text={utils.string.t('app.close')} variant="text" disabled={formState.isSubmitting || isLoading} onClick={handleClose} />
          <Button
            text={utils.string.t('app.confirm')}
            type="submit"
            disabled={formState.isSubmitting || isLoading}
            color="primary"
            danger={!isExtend}
          />
        </FormActions>
      </FormContainer>
    </div>
  );
}

/**
 * @param {string} utcString
 * @returns Date in a different timezone without modifying it
 * @example // Time zone (UTC +00:01)
 * new Date('2025-03-09T00:00:00.000Z') // Sun Mar 09 2025 01:00:00 GMT+0100
 * keepUTCDateInCurrentTimezone('2025-03-09T00:00:00.000Z') //Sun Mar 09 2025 00:00:00 GMT+0100
 */
const keepUTCDateInCurrentTimezone = (utcString) => {
  const date = new Date(utcString);
  const day = new Date().getTimezoneOffset() > 0 ? date.getDate() + 1 : date.getDate();
  return new Date(date.getFullYear(), date.getMonth(), day);
};
