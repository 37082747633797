import { useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

// app
import {
  FormAutocomplete,
  FormAutocompleteMui,
  FormAutocompleteMuiAsync,
  FormCheckbox,
  FormDate,
  FormHidden,
  FormLabel,
  FormRadio,
  FormSelect,
  FormText,
  FormToggle,
} from 'components';

import * as utils from 'utils';

export const AddRiskFormFieldView = ({ field, control, value = null, checkboxProps, selectProps, setValue }) => {
  const fieldMap = {
    text: FormText,
    number: FormText,
    datepicker: FormDate,
    timepicker: FormDate,
    select: FormSelect,
    selectAsync: FormSelect,
    autocompletemuiAsync: FormAutocompleteMuiAsync,
    autocomplete: FormAutocomplete,
    autocompletemui: FormAutocompleteMui,
    radio: FormRadio,
    checkbox: FormCheckbox,
    toggle: FormToggle,
    toggle_group: FormToggle,
    hidden: FormHidden,
    label: FormLabel,
  };

  const Wrapper = useCallback(
    ({ children }) =>
      field.hideFromUser ? (
        <Box
          sx={{
            position: 'absolute',
            width: 1,
            height: 1,
            padding: 0,
            margin: -1,
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: 0,
            clipPath: 'inset(50%)',
          }}
        >
          {children}
        </Box>
      ) : (
        children
      ),
    [field.hideFromUser]
  );
  useEffect(() => {
    if (value === null && field.value) {
      setValue(field.name, field.value);
    }
  }, []);

  const FieldComponent = fieldMap[field.type];

  const dependants = field?.dependants;

  const dependantByValues = dependants?.map((value) => value.split(':')[1]);

  const dependantBy = dependantByValues ? utils.risk.parseWatchFields(dependantByValues) : [];
  const watchFields = field.child
    ? dependantBy?.calculationFields?.flatMap((item) => `${item.field}[${field.index}].${item.child}`)
    : dependantBy?.watchFields;
  const dependentFieldsValues = useWatch({
    control,
    name: watchFields,
  });
  // abort
  if (!FieldComponent) return null;

  return (
    <Wrapper>
      <FieldComponent
        key={field.name}
        control={control}
        setValue={setValue}
        {...checkboxProps}
        {...selectProps}
        {...field}
        disabled={field.disabled || field.readonly}
        dependentFieldsValues={dependentFieldsValues}
        dependentBy={watchFields}
      />
    </Wrapper>
  );
};

AddRiskFormFieldView.propTypes = {
  field: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  checkboxProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
  value: PropTypes.any,
};
