/* eslint-disable no-param-reassign */
import mapboxgl from 'mapbox-gl';
import { calFireRegions, calFireCounties } from './cal_fire_fields';
const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
});

const wildFireFields = [
  { title: 'Incident Name: ', path: 'IncidentName' },
  { title: 'Fire Discovery Date: ', path: 'FireDiscoveryDateTime' },
  { title: 'Fire Out Date: ', path: 'FireOutDateTime' },
  { title: 'Incident Type Category: ', path: 'IncidentTypeCategory' },
  { title: 'Incident Description: ', path: 'IncidentShortDescription' },
  { title: 'Fire Cause: ', path: 'FireCauseGeneral' },
];

const massShootings2022Fields = [
  { title: 'Incident ID: ', path: 'incidentID' },
  { title: 'Date: ', path: 'date' },
  {
    title: 'Address: ',
    path: 'address',
  },
  { title: 'Suspects Arrested: ', path: 'suspectsArrested' },
  { title: 'Suspects Injured: ', path: 'suspectsInjured' },
  { title: 'Suspects Killed: ', path: 'suspectsKilled' },
  { title: 'Victims Injured: ', path: 'victimsInjured' },
  { title: 'Victims Killed: ', path: 'victimsKilled' },
];

const shootingsFields = [
  { title: 'Year: ', path: 'Year' },
  { title: 'State: ', path: 'State' },
  { title: 'Killed: ', path: 'Killed' },
  { title: 'Injured: ', path: 'Injured' },
];

const vtpProjectFields = [
  { title: 'Project Name: ', path: 'ProjectName' },
  { title: 'Project ID: ', path: 'ProjectID' },
  { title: 'Organization: ', path: 'Organization' },
  { title: 'Contact Name: ', path: 'ContactName' },
  { title: 'Contact Phone: ', path: 'ContactPhone' },
  { title: 'Contact Email: ', path: 'ContactEmail' },
  { title: 'Contact Address: ', path: 'ContactAddress' },
  { title: 'Date: ', path: 'Date_' },
  { title: 'Status: ', path: 'Status' },
  { title: 'Rx Broadcast Burn Acres: ', path: 'RxBroadcastBurnAcres' },
  { title: 'Rx Fire Pile Burn Acres: ', path: 'RxFirePileBurnAcres' },
  { title: 'Mechanical Treatment Acres: ', path: 'MechanicalTreatmentAcres' },
  { title: 'Manual Treatment Acres: ', path: 'ManualTreatmentAcres' },
  { title: 'Rx Herbivory Acres: ', path: 'RxHerbivoryAcres' },
  { title: 'Herbicides Acres: ', path: 'HerbicidesAcres' },
  { title: 'Total Acres: ', path: 'TotalAcres' },
  { title: 'Comments: ', path: 'Comments' },
];

const wildfirePreventionGrantFields = [
  { title: 'Tracking Number: ', path: 'Tracking_Number_1' },
  { title: 'Organization: ', path: 'Org_Name' },
  { title: 'Project Title: ', path: 'Project_Title_1' },
  { title: 'County: ', path: 'County' },
  { title: 'Amount Awarded: ', path: 'Amount_Awarded' },
  { title: 'Activity Type: ', path: 'Activity_Type' },
  { title: 'Project Description: ', path: 'Project_Description' },
];

let previousSelectedFeatureId = null;

export const onWildFireHoverPopup = (map, e, layerData) => {
  // Change the cursor style as a UI indicator.
  map.getCanvas().style.cursor = 'pointer';
  // Copy coordinates array.
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];
    let popupData = layerData.id === 'liveWildFires' ? `<strong><u>${layerData.name} </u></strong></br>` : '';
    const details = wildFireFields.map((field) => {
      if (field.path === 'FireDiscoveryDateTime' || field.path === 'FireOutDateTime') {
        return properties[field.path] !== 'null'
          ? `<strong>${field.title}</strong>${new Date(properties[field.path]).toLocaleString()}</br>`
          : '';
      }

      return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
    });
    popupData += details?.length > 0 && details.join('');

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(lngLatLike).setHTML(popupData).addTo(map);
  }
};

export const onShootingsHoverPopup = (map, e, layerData) => {
  // Change the cursor style as a UI indicator.
  map.getCanvas().style.cursor = 'pointer';
  // Copy coordinates array.
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];

    let popupData = `<strong><u>${layerData.name} </u></strong></br>`;
    const dataFields = layerData.id === 'mass-shootings-2022' ? massShootings2022Fields : shootingsFields;

    const details = dataFields.map((field) => {
      if (field.path === 'date') {
        return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
      }

      return properties[field.path] !== 'null' ? `<strong>${field.title}</strong>${properties[field.path]}</br>` : '';
    });
    popupData += details?.length > 0 && details.join('');

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(lngLatLike).setHTML(popupData).addTo(map);
  }
};

export const onExit = (map) => {
  map.getCanvas().style.cursor = 'default';
  setTimeout(() => popup.remove(), 20000);
};

export const onForestManagementPopup = (map, e) => {
  if (e.features.length === 0) return;

  const feature = e.features[0];
  const props = feature.properties;

  // Clear previous selection if exists
  if (previousSelectedFeatureId !== null) {
    map.setFeatureState({ source: 'forest-management', id: previousSelectedFeatureId }, { selected: false });
  }

  // Format dates
  const approvedDate = props.APPROVED ? new Date(props.APPROVED).toLocaleDateString() : 'N/A';

  const html = `
    <h4 style="margin-top: 0; padding-top: 0;">${props.TIMBEROWNR || 'N/A'}</h4>
    <div>
      <strong>Landowner:</strong> ${props.LANDOWNER || 'N/A'}<br/>
      <strong>County:</strong> ${calFireCounties.find((county) => county.code === props.COUNTY)?.name || 'N/A'}<br/>
      <strong>Region:</strong> ${calFireRegions.find((region) => region.code === props.REGION)?.name || 'N/A'}<br/>
      <strong>Area:</strong> ${props.GIS_ACRES?.toFixed(2) || 'N/A'} acres<br/>
      <strong>Status:</strong> ${props.PLAN_STAT || 'N/A'}<br/>
      <strong>Approved:</strong> ${approvedDate}<br/>
      <strong>ID:</strong> ${props.HD_NUM || 'N/A'}<br/>
      <strong>Category:</strong> ${props.SILVI_CAT || 'N/A'}<br/>
      <strong>YARD:</strong> ${props.YARD || 'N/A'}<br/>
      ${props.COMMENTS && props.COMMENTS !== 'null' ? `<strong>Comments:</strong> ${props.COMMENTS}<br/>` : ''}
    </div>
  `;

  // Use the shared popup instance instead of creating a new one
  popup.setLngLat(e.lngLat).setHTML(html).addTo(map);

  // Highlight the selected feature and store its ID
  map.setFeatureState({ source: 'forest-management', id: feature.id }, { selected: true });
  previousSelectedFeatureId = feature.id;
};

export const onVtpProjectHoverPopup = (map, e, layerData) => {
  map.getCanvas().style.cursor = 'pointer';
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];
    let popupData = `<strong><u>${layerData.name}</u></strong></br>`;

    const details = vtpProjectFields.map((field) => {
      if (field.path === 'Date_' && properties[field.path]) {
        return `<strong>${field.title}</strong>${new Date(properties[field.path]).toLocaleDateString()}</br>`;
      }

      if (properties[field.path] != null && properties[field.path] !== '<Null>') {
        return `<strong>${field.title}</strong>${properties[field.path]}</br>`;
      }
      return '';
    });

    popupData += details.join('');

    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);
    popup.setLngLat(lngLatLike).setHTML(popupData).addTo(map);
  }
};

export const onCalFireTreatmentPopup = (map, e, layerData) => {
  map.getCanvas().style.cursor = 'pointer';

  if (e.features?.length > 0) {
    const { properties } = e.features[0];
    const startDate = properties.PROJECT_START_DATE ? new Date(properties.PROJECT_START_DATE).toLocaleDateString() : 'N/A';
    const endDate = properties.PROJECT_END_DATE ? new Date(properties.PROJECT_END_DATE).toLocaleDateString() : 'N/A';
    const lastUpdated = properties.LAST_UPDATED ? new Date(properties.LAST_UPDATED).toLocaleDateString() : 'N/A';

    const popupContent = `
      <div>
        <h4 style="margin-top: 0; padding-top: 0;">${properties.PROJECT_NAME || 'Unnamed Project'}</h4>
        <strong>Project ID:</strong> ${properties.PROJECT_ID || 'N/A'}</br>
        <strong>CalMAPPER ID:</strong> ${properties.CALMAPPER_ID || 'N/A'}</br>
        <strong>Region:</strong> ${properties.REGION || 'N/A'}</br>
        <strong>Unit:</strong> ${properties.UNIT || 'N/A'}</br>
        <strong>Treatment:</strong> ${properties.TREATMENT_NAME || 'N/A'}</br>
        <strong>Objective:</strong> ${properties.TREATMENT_OBJECTIVE || 'N/A'}</br>
        <strong>Acres Treated:</strong> ${properties.TREATED_ACRES?.toFixed(2) || 'N/A'}</br>
        <strong>County:</strong> ${properties.COUNTY_NAME || 'N/A'}</br>    
        <strong>Program:</strong> ${properties.PROJECT_TYPE || 'N/A'}</br>
        <strong>Status:</strong> ${properties.PROJECT_STATUS || 'N/A'}</br> 
        <strong>Activity Status:</strong> ${properties.ACTIVITY_STATUS || 'N/A'}</br>
        <strong>Project Period:</strong> ${startDate} - ${endDate}</br>
        <strong>Last Updated:</strong> ${lastUpdated}</br>
      </div>
    `;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    const { lngLat } = e;
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);
    popup.setLngLat(lngLatLike).setHTML(popupContent).addTo(map);
  }
};

export const onWildfirePreventionGrantPopup = (map, e, layerData) => {
  map.getCanvas().style.cursor = 'pointer';
  const { lngLat } = e;

  if (e.features?.length > 0) {
    const { properties } = e.features[0];
    let popupContent = `<div class="popup-content">
      <h4 style="margin-top: 0; padding-top: 0;">${properties.Org_Name || 'Unnamed Organization'}</h4>`;

    const details = wildfirePreventionGrantFields
      .map((field) => {
        if (properties[field.path] && properties[field.path] !== 'null') {
          if (field.path === 'Amount_Awarded') {
            // Format amount as currency if it exists
            return `<strong>${field.title}</strong>$${properties[field.path]}</br>`;
          }
          return `<strong>${field.title}</strong>${properties[field.path]}</br>`;
        }
        return '';
      })
      .join('');

    popupContent += details + '</div>';

    // Handle coordinate wrapping for zoomed out views
    while (Math.abs(e.lngLat.lng - lngLat.lng) > 180) {
      lngLat.lng += e.lngLat.lng > lngLat.lng ? 360 : -360;
    }

    const lngLatLike = new mapboxgl.LngLat(lngLat.lng, lngLat.lat);
    popup.setLngLat(lngLatLike).setHTML(popupContent).addTo(map);
  }
};
