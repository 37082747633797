import { createContext } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import config from 'config';
import { QB_PRODUCTS, QB_RISK_DETAILS, RISK_COUNTRIES, useGetProducts, useGetRiskCountries, useGetRiskDetails } from 'lib/quoteBind';
// app
import { showModal } from 'stores';
import * as utils from 'utils';

import RiskDetailsView from './RiskDetailsView';
import { useFieldDefinitions } from 'pages/QuoteBind/hooks/useFieldDefinitions';

const INITIAL_RISK_CONTEXT = { risk: null, fieldDefinition: null };

export const RiskDetailsContext = createContext(INITIAL_RISK_CONTEXT);

const RiskDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { data: productsData } = useGetProducts(QB_PRODUCTS);
  const riskProducts = productsData?.data || [];
  const brand = useSelector((state) => state.ui.brand);

  const { data: risk, isLoading: riskIsLoading } = useGetRiskDetails(QB_RISK_DETAILS, { id });

  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { definitionsFields, coverageDefinition } = useFieldDefinitions({ productCode: risk?.riskType });

  const pageTitle = risk?.insured?.name ? risk.insured.name : '';

  const handleReQuoteRisk = (duplicate = false) => {
    const { riskType, id: riskId, risk: riskData } = { ...risk };
    const modalSubtitle = duplicate ? utils.string.t('app.duplicate') : utils.string.t('app.reQuote');
    const modalTitle = `${modalSubtitle} - ${risk?.insured?.name}`;

    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product: { value: riskType },
            riskData,
            riskId,
            duplicate,
          },
        },
      })
    );
  };

  const groups = Object.keys(utils.risk.getGroups(definitionsFields)) || [];

  const locationDefinitions = definitionsFields.find((definition) => definition?.displayOnMap);

  const locationKey = locationDefinitions?.name || '';
  const locations = locationKey ? risk?.risk?.[locationKey] : [];

  const formattedLocations = locations?.map((location, index) => ({
    id: index + 1,
    lat: location.latitude,
    lng: location.longitude,
    locationsFound: 1,
    ...location,
  }));

  const breadcrumbs = [
    {
      name: 'quote-bind',
      label: utils.string.t('admin.quoteBindTitle'),
      link: `${config.routes.quoteBind.root}`,
    },
    {
      name: 'risk',
      label: risk?.insured?.name,
      link: `${config.routes.quoteBind.riskDetails}/${id}`,
      active: true,
      largeFont: true,
    },
  ];

  return id ? (
    <RiskDetailsContext.Provider value={{ risk: risk, fieldDefinition: definitionsFields }}>
      <Helmet>
        <title>{`${pageTitle} - ${utils.app.getAppName(brand)}`}</title>
      </Helmet>
      <RiskDetailsView
        breadcrumbs={breadcrumbs}
        riskIsLoading={riskIsLoading}
        risk={risk}
        riskProducts={riskProducts}
        countries={countries}
        groups={groups}
        definitionsFields={definitionsFields}
        coverageDefinition={coverageDefinition}
        locations={formattedLocations}
        locationDefinitions={locationDefinitions}
        locationKey={locationKey}
        handleReQuoteRisk={handleReQuoteRisk}
      />
    </RiskDetailsContext.Provider>
  ) : null;
};

export default RiskDetails;
