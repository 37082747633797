import { useQuery } from 'react-query';

import * as utils from 'utils';

import { quoteBindAxios } from '../quoteBind.api';

export const useGetDataSourceCalculation = ({ requestFields, riskType, enabled, id }) =>
  useQuery({
    queryKey: ['dataSourceCalculation', requestFields, riskType, id],
    queryFn: async () => {
      const response = await quoteBindAxios.post(`products/${riskType}/calculate/${id}`, {
        ...requestFields,
        riskType,
      });
      return response.data;
    },
    onError: (error) => {
      const errorParams = {
        file: 'useGetDataSourceCalculation.js',
        message: 'API fetch error, initial fetchData',
      };

      utils.api.handleError(error, errorParams);
    },
    enabled,
  });
