import * as utils from 'utils';
import * as handlers from './map.handlers';

const forestManagementState = {
  currentOffset: 0,
  isLoading: false,
  hasMoreData: true,
};

const calFireTreatmentsState = {
  currentOffset: 0,
  isLoading: false,
  hasMoreData: true,
  currentFeatures: [],
};

const wildfirePreventionGrantsState = {
  currentOffset: 0,
  isLoading: false,
  hasMoreData: true,
};

export const mapLayers = {
  buildings: {
    name: '3D Buildings',
    config: [
      {
        name: '3D Buildings',
        id: '3d-buildings',
        source: 'mapbox-streets',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        type: 'fill-extrusion',
        minzoom: 15,
        paint: {
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
          'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
          'fill-extrusion-opacity': 0.6,
        },
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  contours: {
    name: 'Contours',
    config: [
      {
        name: 'Contours',
        id: 'contour',
        layout: {
          visibility: 'visible',
        },
        type: 'line',
        source: 'mapbox-terrain',
        paint: {},
        'source-layer': 'contour',
        visibility: 'none',
      },
      {
        name: 'Contour Labels',
        id: 'contour-label',
        layout: {
          'symbol-placement': 'line',
          'text-field': ['concat', ['to-string', ['get', 'ele']], 'm (', ['to-string', ['*', ['get', 'ele'], 3.3]], 'ft)'],
        },
        type: 'symbol',
        source: 'mapbox-terrain',
        paint: {
          'text-halo-color': 'hsl(0, 0%, 100%)',
          'text-halo-width': 1,
        },
        'source-layer': 'contour',
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  nfhl: {
    name: 'National Flood Hazard Layer',
    config: [
      {
        name: 'National Flood Hazard Layer',
        id: 'nfhl',
        type: 'raster',
        paint: {
          'raster-opacity': 0.5,
        },
        source: {
          type: 'raster',
          tiles: [
            'https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=3857&layers=&layerDefs=&size=256%2c256&imageSR=3857&format=png&transparent=true&dpi=&time=&layerTimeOptions=&dynamicLayers=&gdbVersion=&mapScale=&f=image',
          ],
        },
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  weatherRadar: {
    name: 'Weather Radar Imagery',
    config: [
      {
        name: 'Weather Radar Imagery',
        id: 'noaa-wri',
        type: 'raster',
        paint: {
          'raster-opacity': 0.5,
        },
        source: {
          type: 'raster',
          tiles: [
            'https://new.nowcoast.noaa.gov/arcgis/rest/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=EPSG%3A3857&layers=&layerDefs=&size=256%2c256&imageSR=&format=png&transparent=true&dpi=&time=&layerTimeOptions=&dynamicLayers=&gdbVersion=&mapScale=&f=image',
          ],
        },
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  liveHurricanes: {
    name: 'Live Hurricanes',
    config: [
      {
        name: 'New Noaa',
        id: 'noaa-track-intensity',
        type: 'raster',
        paint: {
          'raster-opacity': 0.5,
        },
        source: {
          type: 'raster',
          tiles: [
            'https://new.nowcoast.noaa.gov/arcgis/rest/services/nowcoast/wwa_meteocean_tropicalcyclones_trackintensityfcsts_time/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=EPSG%3A3857&layers=&layerDefs=&size=256%2c256&imageSR=&format=png&transparent=true&dpi=&time=&layerTimeOptions=&dynamicLayers=&gdbVersion=&mapScale=&f=image',
          ],
        },
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  coastalFlood: {
    name: 'Coastal Flood Exposure (Category 5)',
    config: [
      {
        name: 'Coastal Flood Exposure',
        id: 'costal-flood',
        type: 'raster',
        paint: {
          'raster-opacity': 0.5,
        },
        source: {
          type: 'raster',
          tiles: [
            'https://tiles.arcgis.com/tiles/C8EMgrsFcRFL6LrL/arcgis/rest/services/Storm_Surge_HazardMaps_Category5_v3/MapServer/tile/{z}/{y}/{x}?cacheKey=850d1267fde37737',
          ],
        },
        visibility: 'none',
      },
    ],
    visibility: utils.map.visibility.none,
  },
  liveWildFires: {
    name: 'Live Wildfires',
    id: 'liveWildFires',
    config: [
      {
        name: 'Live Wildfires',
        id: 'liveWildFires',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/WFIGS_Incident_Locations_Current/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson',
        },
        paint: {
          'circle-radius': 5,
          'circle-color': 'orange',
          'circle-stroke-color': 'red',
          'circle-stroke-width': 2,
          'circle-opacity': 0.5,
        },
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onWildFireHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  historicWildFires: {
    name: 'Historic Wildfires',
    id: 'historicWildFires',
    config: [
      {
        name: 'Historic Wildfires',
        id: 'historicWildFires',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/WFIGS_Incident_Locations/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson',
        },
        paint: {
          'circle-radius': 5,
          'circle-color': 'red',
          'circle-stroke-color': 'red',
          'circle-stroke-width': 4,
          'circle-opacity': 0.5,
        },
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onWildFireHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  massShootings2002: {
    name: 'Mass Shootings 2022',
    id: 'mass-shootings-2022',
    config: [
      {
        name: 'Mass Shootings 2022',
        id: 'mass-shootings-2022',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://edgeassets.blob.core.windows.net/geojson/shootings-2022.json',
        },
        paint: {
          'circle-radius': 4,
          'circle-color': '#e93d4c',
          'circle-stroke-color': '#e93d4c',
          'circle-stroke-width': 2,
          'circle-opacity': 0.5,
        },
        filter: ['==', '$type', 'Point'],
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onShootingsHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  allShootings: {
    name: 'All Shootings (2014 - 2019)',
    id: 'all-shootings',
    config: [
      {
        name: 'All Shootings',
        id: 'all-shootings',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://pf-public-json-data.s3.eu-west-2.amazonaws.com/terrorism/allShootings.json',
        },
        paint: {
          'circle-radius': 4,
          'circle-color': '#32CD32',
        },
        filter: ['==', '$type', 'Point'],
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onShootingsHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  massShootings: {
    name: 'Mass Shootings (2014 - 2019)',
    id: 'mass-shootings',
    config: [
      {
        name: 'Mass Shootings',
        id: 'mass-shootings',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://pf-public-json-data.s3.eu-west-2.amazonaws.com/terrorism/massShootings.json',
        },
        paint: {
          'circle-radius': 4,
          'circle-color': '#B42222',
        },
        filter: ['==', '$type', 'Point'],
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onShootingsHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  forestManagement: {
    name: 'CAL FIRE Nonindustrial Timber Management Plans',
    id: 'forest-management',
    config: [
      {
        name: 'CAL FIRE Nonindustrial Timber Management Plans',
        id: 'forest-management',
        type: 'fill',
        source: {
          type: 'geojson',
          data: 'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/CAL_FIRE_Nonindustrial_Timber_Management_Plans_and_Notices_TA83/FeatureServer/1/query?where=1=1&outFields=*&outSR=4326&f=geojson&resultRecordCount=2000&resultOffset=0',
        },
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#FFD700', // Selected color (gold)
            'rgba(255,140,0,0.8)', // Default color - more vibrant orange with higher opacity
          ],
          'fill-outline-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#FF4500', // Selected outline (orange-red)
            '#FF0000', // Default outline - bright red
          ],
          'fill-opacity': 1,
        },
        minzoom: 4, // Lower minzoom to see from further out
        maxzoom: 22,
        visibility: utils.map.visibility.none,
      },
    ],
    events: [
      {
        eventName: 'click',
        handler: handlers.onForestManagementPopup,
      },
      {
        eventName: 'mouseenter',
        handler: (map) => {
          map.getCanvas().style.cursor = 'pointer';
        },
      },
      {
        eventName: 'mouseleave',
        handler: (map) => {
          map.getCanvas().style.cursor = '';
        },
      },
      {
        eventName: 'idle',
        handler: async (map) => {
          console.log('Idle event triggered, state:', forestManagementState);

          // Don't proceed if already loading or no more data
          if (forestManagementState.isLoading || !forestManagementState.hasMoreData) {
            console.log('Skipping fetch:', forestManagementState.isLoading ? 'Already loading' : 'No more data');
            return;
          }

          const source = map.getSource('forest-management');
          if (!source || !source._data) {
            console.log('Source not ready');
            return;
          }

          try {
            forestManagementState.isLoading = true;
            console.log('Fetching batch with offset:', forestManagementState.currentOffset);

            const url = new URL(
              'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/CAL_FIRE_Nonindustrial_Timber_Management_Plans_and_Notices_TA83/FeatureServer/1/query'
            );
            url.searchParams.set('where', '1=1');
            url.searchParams.set('outFields', '*');
            url.searchParams.set('outSR', '4326');
            url.searchParams.set('f', 'geojson');
            url.searchParams.set('resultRecordCount', '2000');
            url.searchParams.set('resultOffset', forestManagementState.currentOffset.toString());

            console.log('Fetching URL:', url.toString());
            const response = await fetch(url);
            const newData = await response.json();

            if (newData.features && newData.features.length > 0) {
              const currentFeatures = source._data.features || [];
              const allFeatures = [...currentFeatures, ...newData.features];
              console.log('Total features:', allFeatures.length);

              source.setData({
                type: 'FeatureCollection',
                features: allFeatures,
              });

              forestManagementState.currentOffset += 2000;
              forestManagementState.hasMoreData = newData.properties?.exceededTransferLimit === true;
            } else {
              forestManagementState.hasMoreData = false;
            }

            console.log('Updated state:', forestManagementState);
          } catch (error) {
            console.error('Error in pagination process:', error);
          } finally {
            forestManagementState.isLoading = false;
          }
        },
      },
    ],
    visibility: utils.map.visibility.none,
  },
  calFireTreatments: {
    name: 'CAL FIRE Treatment Areas',
    id: 'cal-fire-treatments',
    config: [
      {
        name: 'CAL FIRE Treatment Areas',
        id: 'cal-fire-treatments',
        type: 'fill',
        source: {
          type: 'geojson',
          data: 'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/ArcGIS/rest/services/CMDash_v3_view/FeatureServer/2/query?where=1=1&outFields=*&outSR=4326&f=geojson&resultRecordCount=2000&resultOffset=0',
        },
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#4CAF50', // Selected color (green)
            'rgba(76,175,80,0.6)', // Default color - semi-transparent green
          ],
          'fill-outline-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#2E7D32', // Selected outline (darker green)
            '#1B5E20', // Default outline - darkest green
          ],
          'fill-opacity': 0.8,
        },
        minzoom: 4,
        maxzoom: 22,
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onCalFireTreatmentPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
      {
        eventName: 'idle',
        handler: async (map) => {
          if (calFireTreatmentsState.isLoading || !calFireTreatmentsState.hasMoreData) {
            return;
          }

          const source = map.getSource('cal-fire-treatments');
          if (!source || !source._data) {
            return;
          }

          try {
            calFireTreatmentsState.isLoading = true;

            const url = new URL('https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/ArcGIS/rest/services/CMDash_v3_view/FeatureServer/2/query');
            url.searchParams.set('where', '1=1');
            url.searchParams.set('outFields', '*');
            url.searchParams.set('outSR', '4326');
            url.searchParams.set('f', 'geojson');
            url.searchParams.set('resultRecordCount', '2000');
            url.searchParams.set('resultOffset', calFireTreatmentsState.currentOffset.toString());

            const response = await fetch(url);
            const newData = await response.json();

            if (newData.features && newData.features.length > 0) {
              const currentFeatures = source._data.features || [];
              const allFeatures = [...currentFeatures, ...newData.features];

              source.setData({
                type: 'FeatureCollection',
                features: allFeatures,
              });

              calFireTreatmentsState.currentOffset += 2000;
              calFireTreatmentsState.hasMoreData = newData.properties?.exceededTransferLimit === true;
            } else {
              calFireTreatmentsState.hasMoreData = false;
            }
          } catch (error) {
            console.error('Error loading CAL FIRE treatments:', error);
          } finally {
            calFireTreatmentsState.isLoading = false;
          }
        },
      },
    ],
    visibility: utils.map.visibility.none,
  },
  // add new layer here url https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/Cal_VTP_Proposed_Projects_PUBLIC_VIEW/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json
  calVtpProposedProjects: {
    name: 'CAL VTP Proposed Projects',
    id: 'cal-vtp-proposed-projects',
    config: [
      {
        name: 'CAL VTP Proposed Projects',
        id: 'cal-vtp-proposed-projects',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/arcgis/rest/services/Cal_VTP_Proposed_Projects_PUBLIC_VIEW/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=geojson',
        },
        paint: {
          'circle-radius': 6,
          'circle-color': '#0000FF',
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#FFFFFF',
        },
        minzoom: 4,
        maxzoom: 22,
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onVtpProjectHoverPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
    ],
    visibility: utils.map.visibility.none,
  },
  wildfirePreventionGrants: {
    name: 'FY23 24 Wildfire Prevention Grants Awards',
    id: 'wildfire-prevention-grants',
    config: [
      {
        name: 'Wildfire Prevention Grants Awards',
        id: 'wildfire-prevention-grants',
        type: 'circle',
        source: {
          type: 'geojson',
          data: 'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/ArcGIS/rest/services/FY23_24_Wildfire_Prevention_Grants_Awards_Public_View/FeatureServer/0/query?where=1=1&outFields=*&outSR=4326&f=geojson&resultRecordCount=2000&resultOffset=0',
        },
        paint: {
          'circle-radius': 6,
          'circle-color': '#6DA83A', // Using the color from the ArcGIS spec (109,168,58)
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 0.7,
          'circle-opacity': 0.8,
        },
        minzoom: 4,
        maxzoom: 22,
        visibility: 'none',
      },
    ],
    events: [
      {
        eventName: 'mouseenter',
        handler: handlers.onWildfirePreventionGrantPopup,
      },
      {
        eventName: 'mouseleave',
        handler: handlers.onExit,
      },
      {
        eventName: 'idle',
        handler: async (map) => {
          if (wildfirePreventionGrantsState.isLoading || !wildfirePreventionGrantsState.hasMoreData) {
            return;
          }

          const source = map.getSource('wildfire-prevention-grants');
          if (!source || !source._data) {
            return;
          }

          try {
            wildfirePreventionGrantsState.isLoading = true;

            const url = new URL(
              'https://services1.arcgis.com/jUJYIo9tSA7EHvfZ/ArcGIS/rest/services/FY23_24_Wildfire_Prevention_Grants_Awards_Public_View/FeatureServer/0/query'
            );
            url.searchParams.set('where', '1=1');
            url.searchParams.set('outFields', '*');
            url.searchParams.set('outSR', '4326');
            url.searchParams.set('f', 'geojson');
            url.searchParams.set('resultRecordCount', '2000');
            url.searchParams.set('resultOffset', wildfirePreventionGrantsState.currentOffset.toString());

            const response = await fetch(url);
            const newData = await response.json();

            if (newData.features && newData.features.length > 0) {
              const currentFeatures = source._data.features || [];
              const allFeatures = [...currentFeatures, ...newData.features];

              source.setData({
                type: 'FeatureCollection',
                features: allFeatures,
              });

              wildfirePreventionGrantsState.currentOffset += 2000;
              wildfirePreventionGrantsState.hasMoreData = newData.properties?.exceededTransferLimit === true;
            } else {
              wildfirePreventionGrantsState.hasMoreData = false;
            }
          } catch (error) {
            console.error('Error loading Wildfire Prevention Grants:', error);
          } finally {
            wildfirePreventionGrantsState.isLoading = false;
          }
        },
      },
    ],
    visibility: utils.map.visibility.none,
  },
};
