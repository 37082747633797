import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, useRef, Fragment } from 'react';
import chroma from 'chroma-js';
import groupBy from 'lodash/groupBy';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';

// mui
import { Box, Typography } from '@material-ui/core';

// app
import * as utils from 'utils';
import { useQuoteBindAggregateStyles, useCustomTooltipStyles } from './QuoteBindAggregate.styles';
import { useMedia } from 'hooks';

QuoteBindLimitGraphView.propTypes = {
  facilityId: PropTypes.string.isRequired,
};

const colors = {
  'Active Quoted': '#09A9ED',
  'Active Bound': '#2CC6AB',
};

export const CustomTooltip = ({ payload, label }) => {
  const classes = useCustomTooltipStyles();
  const groupedPayload = groupBy(payload, 'chartType');

  const activeBound = groupedPayload['Active Bound'];
  const activeQuoted = groupedPayload['Active Quoted'];
  const limit = groupedPayload['undefined'];

  const totalActive = () => {
    if (activeQuoted) {
      const totalActiveQuoted =
        activeQuoted?.reduce((acc, curr) => {
          return acc + curr.value;
        }, 0) || 0;
      const totalActiveBound =
        activeBound?.reduce((acc, curr) => {
          return acc + curr.value;
        }, 0) || 0;

      return totalActiveQuoted + totalActiveBound;
    } else if (activeBound) {
      return (
        activeBound?.reduce((acc, curr) => {
          return acc + curr.value;
        }, 0) || 0
      );
    }
  };
  const activeTotal = totalActive();

  const orderedGroupedPayload = {
    ...(limit && { limit }),
    ...(activeTotal && { total: [{ name: 'Total', value: activeTotal }] }),
    ...(activeQuoted && { 'Active Quoted': activeQuoted }),
    ...(activeBound && { 'Active Bound': activeBound }),
  };

  return (
    <Box className={classes.toolTip}>
      <Typography style={{ fontWeight: 900 }} variant="caption">
        {label}
      </Typography>
      {Object.keys(orderedGroupedPayload).map((key, index) => {
        const isTitle = key === 'limit' || key === 'total';

        return (
          <Fragment key={`${key}-${index}`}>
            {isTitle ? null : (
              <Typography style={{ fontWeight: 700, color: colors[key] ? colors[key] : null }} variant="caption">
                {key}
              </Typography>
            )}
            {orderedGroupedPayload[key].map((item, index) => (
              <Typography key={`${item?.name}-${index}`} style={{ color: item?.color, marginLeft: isTitle ? 0 : 20 }} variant="caption">
                {`${item?.name}: ${utils.string.t('format.currency', { value: { number: item.value, currency: item.unit || 'USD' } })}`}
              </Typography>
            ))}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default function QuoteBindLimitGraphView({ aggregateLimits }) {
  const media = useMedia();
  const parentRef = useRef(null);
  const [width, setWidth] = useState(1200);
  const classes = useQuoteBindAggregateStyles({ media });

  const parsedAggregateLimits = useMemo(() => {
    let graphData = [];
    if (aggregateLimits?.length > 0) {
      aggregateLimits.forEach((item) => {
        const valueLimits = utils.risk.parsedGraphValues(item?.valueLimits);
        graphData.push({
          title: item?.label,
          data: valueLimits,
        });
      });

      return graphData;
    }
    return [];
  }, [aggregateLimits]);

  useEffect(() => {
    if (parentRef?.current?.offsetWidth) {
      setWidth(parentRef?.current?.offsetWidth);
    }
  }, [parentRef]);

  const customTraveler = ({ x, y, width, height, fill, stroke }) => {
    return (
      <>
        <rect x={x} y={y} width={width} height={height} fill={fill} stroke={stroke} />
      </>
    );
  };

  return (
    <>
      {parsedAggregateLimits.map((item, index) => {
        const isAggregateSet = item?.data?.find((data) => data.facilityLimit > 0);

        const allQuoteLimits =
          item?.data?.reduce((acc, limit) => {
            if (limit.quoteLimits?.length) {
              return [...limit.quoteLimits, ...acc];
            }
            return acc;
          }, []) || [];

        const quoteLimits = [...new Map(allQuoteLimits.map((item) => [item.facilityLabel, item])).values()]
          .map((item) => item.facilityLabel)
          .sort();

        const chartData = item?.data?.map((limit) => {
          const { quoteLimits } = limit;

          const quoteLimitsObj = quoteLimits?.reduce((acc, quoteLimit) => {
            return {
              ...acc,
              [quoteLimit.facilityLabel]: quoteLimit,
            };
          }, {});

          return {
            ...limit,
            ...quoteLimitsObj,
          };
        });

        return (
          <Box key={`${item.title}-${index}`} justifyContent="center">
            <Box justifyContent="left" className={classes.titleDiv}>
              <Typography style={{ color: 'rgb(128, 128, 128)', fontWeight: 500 }} variant="h3" testid="products-aggregate-limits-title">
                {`${item.title} ${utils.string.t('products.aggregateLimits.aggregateLimitTitle')}`}
              </Typography>
            </Box>
            <Box display="flex" className={classes.contentWrapper} ref={parentRef}>
              {item.data?.length > 0 ? (
                <>
                  <ResponsiveContainer width="100%" height={media.mobile ? 300 : 500}>
                    <ComposedChart
                      data={chartData}
                      margin={{
                        top: media.mobile ? 20 : 50,
                        right: media.mobile ? 10 : 30,
                        bottom: media.mobile ? 20 : 50,
                        left: media.mobile ? 20 : 30,
                      }}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis dataKey="label" scale="auto" angle={-45} textAnchor="end" interval={0} />

                      <YAxis tickFormatter={(parameter) => parameter.toLocaleString()} />

                      <Tooltip content={<CustomTooltip />} />

                      <Legend
                        wrapperStyle={{ position: 'sticky', paddingTop: media.mobile ? 0 : 15, width: '100%' }}
                        align="center"
                        verticalAlign="bottom"
                      />

                      {/* Bound Limits */}
                      {quoteLimits ? (
                        <>
                          {quoteLimits.map((quoteLimit, index) => {
                            const boundLimitKey = `${quoteLimit}.boundLimit`;

                            return (
                              <>
                                {boundLimitKey ? (
                                  <Bar
                                    dataKey={boundLimitKey}
                                    barSize={50}
                                    fill={chroma('#2CC6AB').darken(index)}
                                    name={quoteLimit}
                                    stackId="limit"
                                    chartType={utils.string.t('products.aggregateLimits.activeBound')}
                                    unit="USD"
                                    data-testid="products-aggregate-limits-bound-limit"
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </>
                      ) : null}
                      {/* Quoted Limits */}
                      {quoteLimits ? (
                        <>
                          {quoteLimits.map((quoteLimit, index) => {
                            const unboundLimitKey = `${quoteLimit}.unboundLimit`;

                            return (
                              <>
                                {unboundLimitKey ? (
                                  <Bar
                                    dataKey={unboundLimitKey}
                                    barSize={50}
                                    fill={chroma('#09A9ED').darken(index)}
                                    name={quoteLimit}
                                    stackId="limit"
                                    chartType={utils.string.t('products.aggregateLimits.activeQuoted')}
                                    unit="USD"
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </>
                      ) : null}

                      {isAggregateSet ? (
                        <Line
                          type="monotone"
                          dataKey="facilityLimit"
                          stroke="#ff7300"
                          name={utils.string.t('products.aggregateLimits.aggregateLimitLabel')}
                          style={{ zIndex: 1000 }}
                        />
                      ) : null}

                      {item.data?.length > 10 ? (
                        <Brush
                          dataKey="label"
                          height={media.mobile ? 10 : 35}
                          stroke="#7699bc"
                          x={0}
                          y={media.mobile ? 290 : 465}
                          width={parseInt(width)}
                          endIndex={media.mobile ? parseInt(item.data?.length / 4) : parseInt(item.data?.length / 2)}
                          travellerWidth={15}
                          travellerContent={customTraveler}
                        />
                      ) : null}
                    </ComposedChart>
                  </ResponsiveContainer>
                </>
              ) : null}
            </Box>
          </Box>
        );
      })}
    </>
  );
}
