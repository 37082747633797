import { useState } from 'react';
import { useQueryClient } from 'react-query';

// app
import { RiskIssuesView } from './RiskIssues.view';
import { QB_RISK_DETAILS, QB_RISK_QUOTES } from 'lib/quoteBind';
import { RISK_ISSUE_SANCTIONS } from 'consts';

export function RiskIssues({ risk, riskIsLoading }) {
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const queryClient = useQueryClient();

  const riskSanctionsIssues = risk?.issues?.filter((issue) => RISK_ISSUE_SANCTIONS.includes(issue.issueType));
  const insuredSanctionsCheckResult = risk?.insured?.sanctionsCheckResult
    ? { ...risk?.insured?.sanctionsCheckResult, name: risk?.insured?.name }
    : null;
  const reInsuredSanctionsCheckResult = risk?.reinsured?.sanctionsCheckResult
    ? { ...risk?.reinsured?.sanctionsCheckResult, name: risk?.reinsured?.name }
    : null;
  const additionalInsuredSanctionsCheckResult =
    risk?.additionalInsured?.length > 0
      ? risk?.additionalInsured?.map((additionalInsured) =>
          additionalInsured?.sanctionsCheckResult ? { ...additionalInsured.sanctionsCheckResult, name: additionalInsured?.name } : null
        )
      : null;

  const handleRefresh = async () => {
    setIsQuoteLoading(true);
    await queryClient.invalidateQueries(QB_RISK_DETAILS, { id: risk.id });
    await queryClient.invalidateQueries(QB_RISK_QUOTES, risk.id);
    setIsQuoteLoading(false);
  };

  return (
    <RiskIssuesView
      riskSanctionsIssues={riskSanctionsIssues}
      insuredSanctionsCheckResult={insuredSanctionsCheckResult}
      reInsuredSanctionsCheckResult={reInsuredSanctionsCheckResult}
      additionalInsuredSanctionsCheckResult={additionalInsuredSanctionsCheckResult}
      riskIsLoading={riskIsLoading}
      isQuoteLoading={isQuoteLoading}
      handleRefresh={handleRefresh}
    />
  );
}
