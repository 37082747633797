import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { usePutEndorsementQuote } from 'lib/quoteBind';
import * as utils from 'utils';

// app
import { EditRiskQuoteView } from './EditRiskQuote.view';

export const EditEndorsementQuoteProRata = ({ quote, handleClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: updateEndorsementQuotePremium } = usePutEndorsementQuote();

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await updateEndorsementQuotePremium({
      endorsementId: quote.id,
      overriddenProRata: data.overriddenProRata,
      premium: quote.premium,
      validUntil: quote.validUntil,
    });
    if (result) handleCancel();
    else setIsSubmitting(false);
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  // abort
  if (!quote || !quote.id) return null;

  const totalAdditionalPremium = quote.additionalPremiums?.reduce((acc, curr) => acc + curr.premium, 0) || 0;
  const maxProRata = quote.premium + totalAdditionalPremium;

  const fields = [
    [
      {
        gridSize: { xs: 12, sm: 8 },
        type: 'number',
        name: 'overriddenProRata',
        value: quote.proRata,
        label: `${utils.string.t('app.proRata')}${quote?.currency ? ` (${quote?.currency.trim()})` : ``}`,
        validation: Yup.number()
          .nullable()
          .test('overriddenProRata', utils.string.t('validation.number.range', { min: 0, max: maxProRata }), function (v) {
            if (maxProRata > 0) return this.parent.overriddenProRata <= maxProRata && this.parent.overriddenProRata >= 0;
            if (maxProRata < 0) return this.parent.overriddenProRata >= maxProRata && this.parent.overriddenProRata <= 0;
            return false;
          })
          .transform(function (value) {
            return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
          })
          .required(utils.string.t('validation.required')),
        disabled: isSubmitting || quote?.disabledEditPremium,
      },
    ],
  ];

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('risks.update'),
      handler: handleSubmit,
      disabled: isSubmitting,
      tooltip: {
        title: utils.string.t('risks.updateLegend.submit'),
      },
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
      disabled: isSubmitting,
    },
  ];

  return <EditRiskQuoteView fields={fields} actions={actions} />;
};

EditEndorsementQuoteProRata.propTypes = {
  quote: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};
export default EditEndorsementQuoteProRata;
