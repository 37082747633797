export const calFireRegions = [
  {
    name: '1 - Coast',
    code: 1,
  },
  {
    name: '2 - Cascade',
    code: 2,
  },
  {
    name: '3 - South',
    code: 3,
  },
  {
    name: '4 - Sierra',
    code: 4,
  },
];

export const calFireCounties = [
  {
    name: 'Alameda',
    code: 'ALA',
  },
  {
    name: 'Alpine',
    code: 'ALP',
  },
  {
    name: 'Amador',
    code: 'AMA',
  },
  {
    name: 'Butte',
    code: 'BUT',
  },
  {
    name: 'Calaveras',
    code: 'CAL',
  },
  {
    name: 'Colusa',
    code: 'COL',
  },
  {
    name: 'Contra Costa',
    code: 'CCA',
  },
  {
    name: 'Del Norte',
    code: 'DEL',
  },
  {
    name: 'El Dorado',
    code: 'ELD',
  },
  {
    name: 'Fresno',
    code: 'FRE',
  },
  {
    name: 'Glenn',
    code: 'GLE',
  },
  {
    name: 'Humboldt',
    code: 'HUM',
  },
  {
    name: 'Imperial',
    code: 'IMP',
  },
  {
    name: 'Inyo',
    code: 'INY',
  },
  {
    name: 'Kern',
    code: 'KER',
  },
  {
    name: 'Kings',
    code: 'KIN',
  },
  {
    name: 'Lake',
    code: 'LAK',
  },
  {
    name: 'Lassen',
    code: 'LAS',
  },
  {
    name: 'Los Angeles',
    code: 'LAN',
  },
  {
    name: 'Madera',
    code: 'MAD',
  },
  {
    name: 'Marin',
    code: 'MAN',
  },
  {
    name: 'Mariposa',
    code: 'MAR',
  },
  {
    name: 'Mendocino',
    code: 'MEN',
  },
  {
    name: 'Merced',
    code: 'MER',
  },
  {
    name: 'Modoc',
    code: 'MOD',
  },
  {
    name: 'Mono',
    code: 'MOO',
  },
  {
    name: 'Monterey',
    code: 'MON',
  },
  {
    name: 'Napa',
    code: 'NAP',
  },
  {
    name: 'Nevada',
    code: 'NEV',
  },
  {
    name: 'Orange',
    code: 'ORA',
  },
  {
    name: 'Placer',
    code: 'PLA',
  },
  {
    name: 'Plumas',
    code: 'PLU',
  },
  {
    name: 'Riverside',
    code: 'RIV',
  },
  {
    name: 'Sacramento',
    code: 'SAC',
  },
  {
    name: 'San Benito',
    code: 'SBO',
  },
  {
    name: 'San Bernardino',
    code: 'SBR',
  },
  {
    name: 'San Diego',
    code: 'SDO',
  },
  {
    name: 'San Francisco',
    code: 'SFO',
  },
  {
    name: 'San Joaquin',
    code: 'SJN',
  },
  {
    name: 'San Luis Obispo',
    code: 'SLO',
  },
  {
    name: 'San Mateo',
    code: 'SMO',
  },
  {
    name: 'Santa Barbara',
    code: 'SBA',
  },
  {
    name: 'Santa Clara',
    code: 'SCL',
  },
  {
    name: 'Santa Cruz',
    code: 'SCR',
  },
  {
    name: 'Shasta',
    code: 'SHA',
  },
  {
    name: 'Sierra',
    code: 'SIE',
  },
  {
    name: 'Siskiyou',
    code: 'SIS',
  },
  {
    name: 'Solano',
    code: 'SOL',
  },
  {
    name: 'Sonoma',
    code: 'SON',
  },
  {
    name: 'Stanislaus',
    code: 'STA',
  },
  {
    name: 'Sutter',
    code: 'SUT',
  },
  {
    name: 'Tehama',
    code: 'TEH',
  },
  {
    name: 'Trinity',
    code: 'TRI',
  },
  {
    name: 'Tulare',
    code: 'TUL',
  },
  {
    name: 'Tuolumne',
    code: 'TUO',
  },
  {
    name: 'Ventura',
    code: 'VEN',
  },
  {
    name: 'Yolo',
    code: 'YOL',
  },
  {
    name: 'Yuba',
    code: 'YUB',
  },
];

export const calFireFields = [
  {
    name: 'OBJECTID',
    type: 'esriFieldTypeOID',
    alias: 'OBJECTID',
    sqlType: 'sqlTypeOther',
    domain: null,
    defaultValue: null,
  },
  {
    name: 'GIS_ACRES',
    type: 'esriFieldTypeDouble',
    alias: 'GIS_ACRES',
    sqlType: 'sqlTypeOther',
    domain: null,
    defaultValue: null,
  },
  {
    name: 'REGION',
    type: 'esriFieldTypeSmallInteger',
    alias: 'REGION',
    sqlType: 'sqlTypeOther',
    domain: {
      type: 'codedValue',
      name: 'Regions',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: '1 - Coast',
          code: 1,
        },
        {
          name: '2 - Cascade',
          code: 2,
        },
        {
          name: '3 - South',
          code: 3,
        },
        {
          name: '4 - Sierra',
          code: 4,
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'WFMP_YEAR',
    type: 'esriFieldTypeSmallInteger',
    alias: 'WFMP_YEAR',
    sqlType: 'sqlTypeOther',
    domain: null,
    defaultValue: null,
  },
  {
    name: 'WFMP_NUM',
    type: 'esriFieldTypeSmallInteger',
    alias: 'WFMP_NUM',
    sqlType: 'sqlTypeOther',
    domain: null,
    defaultValue: null,
  },
  {
    name: 'COUNTY',
    type: 'esriFieldTypeString',
    alias: 'COUNTY',
    sqlType: 'sqlTypeOther',
    length: 3,
    domain: {
      type: 'codedValue',
      name: 'Counties',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Alameda',
          code: 'ALA',
        },
        {
          name: 'Alpine',
          code: 'ALP',
        },
        {
          name: 'Amador',
          code: 'AMA',
        },
        {
          name: 'Butte',
          code: 'BUT',
        },
        {
          name: 'Calaveras',
          code: 'CAL',
        },
        {
          name: 'Colusa',
          code: 'COL',
        },
        {
          name: 'Contra Costa',
          code: 'CCA',
        },
        {
          name: 'Del Norte',
          code: 'DEL',
        },
        {
          name: 'El Dorado',
          code: 'ELD',
        },
        {
          name: 'Fresno',
          code: 'FRE',
        },
        {
          name: 'Glenn',
          code: 'GLE',
        },
        {
          name: 'Humboldt',
          code: 'HUM',
        },
        {
          name: 'Imperial',
          code: 'IMP',
        },
        {
          name: 'Inyo',
          code: 'INY',
        },
        {
          name: 'Kern',
          code: 'KER',
        },
        {
          name: 'Kings',
          code: 'KIN',
        },
        {
          name: 'Lake',
          code: 'LAK',
        },
        {
          name: 'Lassen',
          code: 'LAS',
        },
        {
          name: 'Los Angeles',
          code: 'LAN',
        },
        {
          name: 'Madera',
          code: 'MAD',
        },
        {
          name: 'Marin',
          code: 'MAN',
        },
        {
          name: 'Mariposa',
          code: 'MAR',
        },
        {
          name: 'Mendocino',
          code: 'MEN',
        },
        {
          name: 'Merced',
          code: 'MER',
        },
        {
          name: 'Modoc',
          code: 'MOD',
        },
        {
          name: 'Mono',
          code: 'MOO',
        },
        {
          name: 'Monterey',
          code: 'MON',
        },
        {
          name: 'Napa',
          code: 'NAP',
        },
        {
          name: 'Nevada',
          code: 'NEV',
        },
        {
          name: 'Orange',
          code: 'ORA',
        },
        {
          name: 'Placer',
          code: 'PLA',
        },
        {
          name: 'Plumas',
          code: 'PLU',
        },
        {
          name: 'Riverside',
          code: 'RIV',
        },
        {
          name: 'Sacramento',
          code: 'SAC',
        },
        {
          name: 'San Benito',
          code: 'SBO',
        },
        {
          name: 'San Bernardino',
          code: 'SBR',
        },
        {
          name: 'San Diego',
          code: 'SDO',
        },
        {
          name: 'San Francisco',
          code: 'SFO',
        },
        {
          name: 'San Joaquin',
          code: 'SJN',
        },
        {
          name: 'San Luis Obispo',
          code: 'SLO',
        },
        {
          name: 'San Mateo',
          code: 'SMO',
        },
        {
          name: 'Santa Barbara',
          code: 'SBA',
        },
        {
          name: 'Santa Clara',
          code: 'SCL',
        },
        {
          name: 'Santa Cruz',
          code: 'SCR',
        },
        {
          name: 'Shasta',
          code: 'SHA',
        },
        {
          name: 'Sierra',
          code: 'SIE',
        },
        {
          name: 'Siskiyou',
          code: 'SIS',
        },
        {
          name: 'Solano',
          code: 'SOL',
        },
        {
          name: 'Sonoma',
          code: 'SON',
        },
        {
          name: 'Stanislaus',
          code: 'STA',
        },
        {
          name: 'Sutter',
          code: 'SUT',
        },
        {
          name: 'Tehama',
          code: 'TEH',
        },
        {
          name: 'Trinity',
          code: 'TRI',
        },
        {
          name: 'Tulare',
          code: 'TUL',
        },
        {
          name: 'Tuolumne',
          code: 'TUO',
        },
        {
          name: 'Ventura',
          code: 'VEN',
        },
        {
          name: 'Yolo',
          code: 'YOL',
        },
        {
          name: 'Yuba',
          code: 'YUB',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'TIMBEROWNR',
    type: 'esriFieldTypeString',
    alias: 'TIMBEROWNR',
    sqlType: 'sqlTypeOther',
    length: 100,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'LANDOWNER',
    type: 'esriFieldTypeString',
    alias: 'LANDOWNER',
    sqlType: 'sqlTypeOther',
    length: 100,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'SILVI_1',
    type: 'esriFieldTypeString',
    alias: 'SILVI_1',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: {
      type: 'codedValue',
      name: 'Silvi_1',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Alternative Prescription',
          code: 'Alternative Prescription',
        },
        {
          name: 'Aspen/Meadow/Wet Area Restoration',
          code: 'Aspen/Meadow/Wet Area Restoration',
        },
        {
          name: 'Clearcut',
          code: 'Clearcut',
        },
        {
          name: 'Commercial Thin',
          code: 'Commercial Thin',
        },
        {
          name: 'Conversion',
          code: 'Conversion',
        },
        {
          name: 'Fuelbreak/Defensible Space',
          code: 'Fuelbreak/Defensible Space',
        },
        {
          name: 'Group Selection',
          code: 'Group Selection',
        },
        {
          name: 'No Harvest Area',
          code: 'No Harvest Area',
        },
        {
          name: 'Non Standard Practice',
          code: 'Non-Standard Practice',
        },
        {
          name: 'Oak Woodland Management',
          code: 'Oak Woodland Management',
        },
        {
          name: 'Rehabilitation of Understocked',
          code: 'Rehabilitation of Understocked',
        },
        {
          name: 'Road Right-of-Way',
          code: 'Road Right-of-Way',
        },
        {
          name: 'Sanitation Salvage',
          code: 'Sanitation Salvage',
        },
        {
          name: 'Seed Tree Rem/Commercial Thin',
          code: 'Seed Tree Rem/Commercial Thin',
        },
        {
          name: 'Seed Tree Removal Step',
          code: 'Seed Tree Removal Step',
        },
        {
          name: 'Seed Tree Seed Step',
          code: 'Seed Tree Seed Step',
        },
        {
          name: 'Selection',
          code: 'Selection',
        },
        {
          name: 'Shelterwood Prep Step',
          code: 'Shelterwood Prep Step',
        },
        {
          name: 'Shelterwood Rem/Commercial Thin',
          code: 'Shelterwood Rem/Commercial Thin',
        },
        {
          name: 'Shelterwood Removal Step',
          code: 'Shelterwood Removal Step',
        },
        {
          name: 'Shelterwood Seed Step',
          code: 'Shelterwood Seed Step',
        },
        {
          name: 'Special Treatment Area',
          code: 'Special Treatment Area',
        },
        {
          name: 'Substantially Damaged Timberland',
          code: 'Substantially Damaged Timberland',
        },
        {
          name: 'Transition',
          code: 'Transition',
        },
        {
          name: 'Unevenaged Management',
          code: 'Unevenaged Management',
        },
        {
          name: 'Variable Retention',
          code: 'Variable Retention',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'SILVI_2',
    type: 'esriFieldTypeString',
    alias: 'SILVI_2',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: {
      type: 'codedValue',
      name: 'Silvi_2',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Clearcut',
          code: 'Clearcut',
        },
        {
          name: 'Commercial Thin',
          code: 'Commercial Thin',
        },
        {
          name: 'Group Selection',
          code: 'Group Selection',
        },
        {
          name: 'Hardwood Release',
          code: 'Hardwood Release',
        },
        {
          name: 'Rehabilitation of Understocked',
          code: 'Rehabilitation of Understocked',
        },
        {
          name: 'Sanitation Salvage',
          code: 'Sanitation Salvage',
        },
        {
          name: 'Seed Tree Rem/Commercial Thin',
          code: 'Seed Tree Rem/Commercial Thin',
        },
        {
          name: 'Seed Tree Removal Step',
          code: 'Seed Tree Removal Step',
        },
        {
          name: 'Seed Tree Seed Step',
          code: 'Seed Tree Seed Step',
        },
        {
          name: 'Selection',
          code: 'Selection',
        },
        {
          name: 'Shelterwood Prep Step',
          code: 'Shelterwood Prep Step',
        },
        {
          name: 'Shelterwood Rem/Commercial Thin',
          code: 'Shelterwood Rem/Commercial Thin',
        },
        {
          name: 'Shelterwood Removal Step',
          code: 'Shelterwood Removal Step',
        },
        {
          name: 'Shelterwood Seed Step',
          code: 'Shelterwood Seed Step',
        },
        {
          name: 'Special Treatment Area',
          code: 'Special Treatment Area',
        },
        {
          name: 'Transition',
          code: 'Transition',
        },
        {
          name: 'Variable Retention',
          code: 'Variable Retention',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'SILVI_CAT',
    type: 'esriFieldTypeString',
    alias: 'SILVI_CAT',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: {
      type: 'codedValue',
      name: 'SilviCat',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Evenaged Management',
          code: 'Evenaged Management',
        },
        {
          name: 'Intermediate Treatments',
          code: 'Intermediate Treatments',
        },
        {
          name: 'No Harvest Area',
          code: 'No Harvest Area',
        },
        {
          name: 'Road Right-of-Way',
          code: 'Road Right-of-Way',
        },
        {
          name: 'Special Prescriptions & Other Management',
          code: 'Special Prescriptions & Other Management',
        },
        {
          name: 'Timberland Conversion',
          code: 'Timberland Conversion',
        },
        {
          name: 'Unevenaged Management',
          code: 'Unevenaged Management',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'YARD',
    type: 'esriFieldTypeString',
    alias: 'YARD',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: {
      type: 'codedValue',
      name: 'YardTypes',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Tractor or Skidder',
          code: 'Tractor or Skidder',
        },
        {
          name: 'Cable System',
          code: 'Cable System',
        },
        {
          name: 'Tractor with Cable option',
          code: 'Tractor/Cable option',
        },
        {
          name: 'Cable with Tractor option',
          code: 'Cable/Tractor option',
        },
        {
          name: 'Tractor with Helicopter option',
          code: 'Tractor/Helicopter option',
        },
        {
          name: 'Cable with Helicopter option',
          code: 'Cable/Helicopter option',
        },
        {
          name: 'Helicopter with Tractor option',
          code: 'Helicopter/Tractor option',
        },
        {
          name: 'Helicopter with Cable option',
          code: 'Helicopter/Cable option',
        },
        {
          name: 'Tractor with Cable and Helicopter option',
          code: 'Tractor/Cable/Helicopter option',
        },
        {
          name: 'Cable with Tractor and Helicopter option',
          code: 'Cable/Tractor/Helicopter option',
        },
        {
          name: 'Balloon or Helicopter',
          code: 'Balloon or Helicopter',
        },
        {
          name: 'Animal',
          code: 'Animal',
        },
        {
          name: 'Other',
          code: 'Other',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'UNIT',
    type: 'esriFieldTypeString',
    alias: 'UNIT',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'PLAN_STAT',
    type: 'esriFieldTypeString',
    alias: 'PLAN_STAT',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: {
      type: 'codedValue',
      name: 'PlanStat',
      mergePolicy: 'esriMPTDefaultValue',
      splitPolicy: 'esriSPTDuplicate',
      codedValues: [
        {
          name: 'Approved',
          code: 'Approved',
        },
        {
          name: 'Completed',
          code: 'Completed',
        },
        {
          name: 'Unlogged',
          code: 'Unlogged',
        },
        {
          name: 'Withdrawn',
          code: 'Withdrawn',
        },
      ],
    },
    defaultValue: null,
  },
  {
    name: 'APPROVED',
    type: 'esriFieldTypeDate',
    alias: 'APPROVED',
    sqlType: 'sqlTypeOther',
    length: 8,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'CANCELLED',
    type: 'esriFieldTypeDate',
    alias: 'CANCELLED',
    sqlType: 'sqlTypeOther',
    length: 8,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'COMMENTS',
    type: 'esriFieldTypeString',
    alias: 'COMMENTS',
    sqlType: 'sqlTypeOther',
    length: 300,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'SPATL_MOD',
    type: 'esriFieldTypeString',
    alias: 'SPATL_MOD',
    sqlType: 'sqlTypeOther',
    length: 50,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'MODIFIED',
    type: 'esriFieldTypeDate',
    alias: 'MODIFIED',
    sqlType: 'sqlTypeOther',
    length: 8,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'HD_NUM',
    type: 'esriFieldTypeString',
    alias: 'HD_NUM',
    sqlType: 'sqlTypeOther',
    length: 35,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'GLOBALID',
    type: 'esriFieldTypeGlobalID',
    alias: 'GLOBALID',
    sqlType: 'sqlTypeOther',
    length: 38,
    domain: null,
    defaultValue: null,
  },
  {
    name: 'Shape__Area',
    type: 'esriFieldTypeDouble',
    alias: 'Shape__Area',
    sqlType: 'sqlTypeDouble',
    domain: null,
    defaultValue: null,
  },
  {
    name: 'Shape__Length',
    type: 'esriFieldTypeDouble',
    alias: 'Shape__Length',
    sqlType: 'sqlTypeDouble',
    domain: null,
    defaultValue: null,
  },
];
