/* eslint-disable no-underscore-dangle */
import jsonDiff from './lib/jsonDiff';

const isNullorEmpty = (arr) => !arr || arr.length === 0;

const hasValueChanged = (value) => `${value.__new}` !== `${value.__old}`;

const endorsementDiff = (a, b) => {
  const diff = jsonDiff.diff(a, b);
  const result = [];

  Object.entries(diff).forEach(([key, value]) => {
    if (value === undefined) return;
    // buildings or trucks
    if (Array.isArray(value)) {
      value.forEach(([operation, changes], index) => {
        if (operation === '+') {
          result.push({
            op: 'add',
            path: `${key}.${index}`,
            value: changes,
          });
        }
        if (operation === '-') {
          result.push({
            op: 'remove',
            path: `${key}.${index}`,
          });
        }
        if (operation === '~') {
          Object.entries(changes).forEach(([prop, change]) => {
            if (!change) return;
            const newValue = `${change.__new?.value ?? change.__new}`;
            const oldValue = `${change.__old?.value ?? change.__old}`;
            if (newValue !== oldValue) {
              result.push({
                op: 'replace',
                path: `${key}.${index}.${prop}`,
                value: change.__new?.label ?? change.__new ?? '',
              });
            }
          });
        }
      });
    } else {
      if (typeof value.__new === 'object') {
        if (hasValueChanged(value) && isNullorEmpty(value?.__old) && Array.isArray(value?.__new)) {
          value.__new.forEach((newValue, index) =>
            result.push({
              op: 'add',
              path: `${key}.${index}`,
              value: newValue,
            })
          );
        } else if (hasValueChanged(value) && isNullorEmpty(value?.__new) && Array.isArray(value?.old) && value?.old?.length > 0) {
          result.push({
            op: 'remove',
            path: key,
          });
        } else if (!!value.__new && `${value.__new.value}` !== `${value.__old}`) {
          result.push({
            op: 'replace',
            path: key,
            value: value.__new.label,
          });
        }
      }
      if (typeof value.__new !== 'object' && hasValueChanged(value)) {
        result.push({
          op: 'replace',
          path: key,
          value: value?.__new ?? '',
        });
      }
    }
  });

  return result;
};

const diff = {
  endorsementDiff,
};

export default diff;
