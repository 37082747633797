import { useState } from 'react';
import isNumber from 'lodash/isNumber';
import * as Yup from 'yup';

import { RISK_QUOTE_STATUS_QUOTED } from 'consts';
import { usePatchQuote } from 'lib/quoteBind';
import * as utils from 'utils';

// app
import { EditRiskQuoteView } from './EditRiskQuote.view';
import { isValid } from 'date-fns';

export const EditRiskQuote = ({ quote, handleClose, hasQuoteAdditionalPremium }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: patchRiskQuote } = usePatchQuote();

  const quoteStatus = quote.response ? quote.response.responseStatus : quote.status;
  const isQuoted = quoteStatus === RISK_QUOTE_STATUS_QUOTED;

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await patchRiskQuote({
      formData: data,
      quote,
      accept: false,
      hasQuoteAdditionalPremium,
    });
    if (result) handleClose?.();
    else setIsSubmitting(false);
  };

  const handleSubmitAndAccept = async (data) => {
    setIsSubmitting(true);
    const result = await patchRiskQuote({ formData: data, quote, accept: true });
    if (result) handleClose?.();
    else setIsSubmitting(false);
  };

  // abort
  if (!quote || !quote.id) return null;

  const currencyLabel = quote.currency ? ` (${quote.currency.trim()})` : '';

  const fields = [
    [
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'number',
        name: 'grossPremium',
        value: isNumber(quote.commission?.revisedPremium) ? quote.commission.revisedPremium : quote.grossPremium,
        label: `${utils.string.t('risks.grossPremium')}${currencyLabel}`,
        validation: Yup.number()
          .nullable()
          .min(0, utils.string.t('risks.validation.group.nonegative'))
          .transform(function (value) {
            return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
          })
          .required(utils.string.t('validation.required')),
        disabled: isSubmitting,
      },
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'datepicker',
        name: 'validUntil',
        label: utils.string.t('risks.quoteValidUntil.label'),
        value: quote.validUntil,
        outputFormat: 'iso',
        muiPickerProps: {
          minDate: utils.date.tomorrow(),
        },
        muiComponentProps: {
          fullWidth: true,
          disabled: isSubmitting,
        },
        validation: Yup.string()
          .nullable()
          .required(utils.string.t('risks.quoteValidUntil.required'))
          .test('valid-date', utils.string.t('risks.quoteValidUntil.validDate'), (value) => isValid(new Date(value))),
      },
      hasQuoteAdditionalPremium && {
        gridSize: { xs: 12, sm: 6 },
        type: 'number',
        name: 'totalQuoteAdditionalPremium',
        value: quote.totalQuoteAdditionalPremium ?? 0,
        label: `${utils.string.t('risks.additionalPremium')}${currencyLabel}`,
        validation: Yup.number()
          .nullable()
          .min(0, utils.string.t('risks.validation.group.nonegative'))
          .transform(function (value) {
            return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
          })
          .required(utils.string.t('validation.required')),
        disabled: isSubmitting,
      },
    ].filter(Boolean),
    {
      type: 'hidden',
      name: 'riskId',
      value: quote.riskId,
    },
  ];

  const updateAndAccept = {
    name: 'secondary',
    label: utils.string.t('risks.updateAndAccept'),
    handler: handleSubmitAndAccept,
    disabled: isSubmitting,
    tooltip: {
      title: utils.string.t('risks.updateLegend.submitAccept'),
    },
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('risks.update'),
      handler: handleSubmit,
      disabled: isSubmitting,
      tooltip: {
        title: utils.string.t('risks.updateLegend.submit'),
      },
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleClose,
      disabled: isSubmitting,
    },
  ];

  return <EditRiskQuoteView fields={fields} actions={isQuoted ? actions : [updateAndAccept, ...actions]} />;
};

export default EditRiskQuote;
