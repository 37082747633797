import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
// app
import * as utils from 'utils';
import styles from './FormText.styles';

// mui
import { makeStyles, TextField } from '@material-ui/core';

FormTextView.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'textarea', 'number', 'date']).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  error: PropTypes.object,
  muiComponentProps: PropTypes.object,
  classes: PropTypes.object,
  compact: PropTypes.bool,
};

FormTextView.defaultProps = {
  type: 'text',
  muiComponentProps: {},
  compact: false,
  disabled: false,
};

export function FormTextView({
  control,
  name,
  value,
  defaultValue,
  type,
  label,
  placeholder,
  disabled,
  hint,
  error,
  compact,
  muiComponentProps,
  onChange,
  classes,
  rules,
  thousandSeparator,
}) {
  const componentClasses = makeStyles(styles, { name: 'FormText' })();

  const handleOnChange = onChange ? onChange : null;

  const fieldClasses = {
    ...muiComponentProps.classes,
    ...classes,
  };
  const isNumericField = type === 'number';

  const props = {
    type: isNumericField ? 'text' : type,
    id: name,
    name,
    label,
    placeholder,
    disabled,
    rules,
    variant: 'outlined',
    ...muiComponentProps,
    InputProps: {
      ...muiComponentProps?.inputProps,
    },
    InputLabelProps: {
      shrink: true,
      ...muiComponentProps.InputLabelProps,
    },
    margin: 'normal',
    fullWidth: typeof muiComponentProps.fullWidth !== 'undefined' ? muiComponentProps.fullWidth : true,
    classes: {
      ...fieldClasses,
      root: classnames(fieldClasses.root, componentClasses.root, { [componentClasses.compact]: compact }),
    },
  };

  const thousandSeparatorChar = thousandSeparator === false ? '' : ',';

  const helperTextProps = {
    error: Boolean(error),
    helperText: (error && error.message) || hint,
  };

  return (
    <>
      {control ? (
        isNumericField ? (
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <NumberFormat
                  value={value}
                  customInput={TextField}
                  isNumericString
                  {...props}
                  {...helperTextProps}
                  thousandSeparator={thousandSeparatorChar}
                  onValueChange={(v) => {
                    onChange(v.value);
                  }}
                />
              );
            }}
            onChange={([data]) => {
              return data.value;
            }}
            control={control}
            name={name}
            value={value}
            defaultValue={defaultValue}
          />
        ) : (
          <Controller
            render={({ field }) => (
              <TextField
                onChange={(e) => {
                  if (utils.generic.isFunction(handleOnChange)) e.target.value = handleOnChange(e.target.value, control);
                  field.onChange(e);
                }}
                name={field.name}
                value={field.value}
                {...props}
                {...helperTextProps}
              />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            {...props}
          />
        )
      ) : (
        <TextField {...props} value={value} {...helperTextProps} />
      )}
    </>
  );
}
