import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useGetDataSourceCalculation } from 'lib/quoteBind';
import * as utils from 'utils';

export const useFieldCalculation = ({ field, control, riskType, setValue }) => {
  const calculateField = !!field?.calculate?.length;
  const calculateFromFields = !!field?.calculateFromFields?.fields?.length;

  const fieldsForCalculation = useMemo(() => {
    if (calculateField) {
      return utils.risk.parseWatchFields(field?.calculate);
    }
    if (calculateFromFields) {
      return utils.risk.parseWatchFields(field?.calculateFromFields.fields, field?.calculateFromFields.key);
    }
    return [];
    // Field definition is not changed, so we don't need to re-run this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateFieldsValues = useWatch({
    control,
    name: field.child
      ? fieldsForCalculation?.calculationFields?.flatMap((item) => `${item.field}[${field.index}].${item.child}`)
      : fieldsForCalculation?.watchFields,
  });

  const getRequestFields = () => {
    const requestFields = {};

    if (field.child) {
      fieldsForCalculation?.calculationFields?.forEach((item, index) => {
        if (!Object.keys(requestFields).includes(item.field)) {
          requestFields[item.field] = [];
        }
        new Array(field.index + 1).fill(null).forEach((_, i) => {
          if (!requestFields[item.field][i]) requestFields[item.field][i] = {};
        });
        requestFields[item.field][field.index][item.child] = calculateFieldsValues[index];
      });
    } else {
      fieldsForCalculation?.watchFields?.forEach((watchField, index) => {
        requestFields[watchField] = calculateFieldsValues[index];
      });
    }

    return requestFields;
  };

  const requestFields = getRequestFields();

  // Calculation using fields
  useEffect(() => {
    if (calculateFromFields) {
      const calculatedValue = utils.risk.calculateValueFromFields(
        fieldsForCalculation?.calculationFields,
        calculateFieldsValues,
        field?.calculateFromFields?.operation
      );

      setValue(field.name, calculatedValue);
    }
    // Field definition is not changed, so we don't need to re-run this hook
    // setValue is form state setter, so we don't need to re-run this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateFieldsValues]);

  // Calculation using api
  const { data } = useGetDataSourceCalculation({
    requestFields,
    riskType,
    enabled: calculateField && Object.values(requestFields).some((value) => value),
    id: `${field.child ? `${field.index}:${field.child}` : field.name}`,
  });

  useEffect(() => {
    if (typeof data?.result !== 'undefined') {
      setValue(field.name, data.result);
    }
    // setValue is form state setter, so we don't need to re-run this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
