import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'components';
import { Add } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

// app
import * as utils from 'utils';
import { showModal, selectIsProducer, selectPartyClient } from 'stores';

import { RiskContext } from 'forms/AddEditQuoteBind/AddEditQuoteBind';

const LabelAndCreate = ({ label, name, value, targetField, multiple = false }) => {
  const dispatch = useDispatch();

  const { setValue, control } = useFormContext();
  const [currentValue, currentTargetField] = useWatch({ control, name: [name, targetField] });
  const hasValueSet = value && (!Array.isArray(value) || utils.generic.isValidArray(value, true)) ? true : false;
  const userIsProducer = useSelector(selectIsProducer);
  const partyClient = useSelector(selectPartyClient);
  const { risk } = useContext(RiskContext);

  const productCode = risk?.riskType;

  const submitHandlerCallback = useCallback(
    (values) => {
      if (utils.generic.isFunction(setValue)) {
        if (multiple) {
          const formattedValue = {
            id: values.id,
            label: values?.name || '',
            value: values?.id,
            ...values,
          };

          const currentValues = Array.isArray(currentValue) ? currentValue : [];

          const valueExists = currentValues.some((item) => item.id === formattedValue.id);

          const newValue = valueExists ? currentValues : [...currentValues, formattedValue];

          setValue(name, newValue);
          const currentTargetFieldValues = Array.isArray(currentTargetField) ? currentTargetField : [];

          const targetFieldValueExists = currentTargetFieldValues.some((item) => item.id === values.id);

          const newtargetFieldValue = targetFieldValueExists ? currentTargetFieldValues : [...currentTargetFieldValues, values];

          setValue(targetField, newtargetFieldValue);
        } else {
          const formattedValue = {
            id: values.id,
            label: values?.name || '',
            value: values?.id,
          };

          setValue(name, formattedValue);
          setValue(targetField, values);
        }
      }
    },
    [name, multiple, targetField]
  );

  const getModalConfig = (component, title, additionalProps = {}) => ({
    component,
    props: {
      title,
      fullWidth: true,
      maxWidth: 'lg',
      componentProps: {
        isAddRiskForm: true,
        submitHandler: (response) => submitHandlerCallback(response),
        ...additionalProps,
      },
    },
  });

  const getEditConfig = (type) => {
    const configs = {
      client: {
        component: 'EDIT_PRODUCTS_CLIENT',
        title: userIsProducer ? 'products.admin.clients.producerInformation' : 'products.admin.clients.edit',
        props: {
          id: value?.value,
          isCreateClientModal: true,
          isEdit: true,
          isProducer: userIsProducer,
        },
      },
      insured: {
        component: 'EDIT_PRODUCTS_INSURED',
        title: 'products.admin.insureds.edit',
        props: {
          id: value?.value,
          reInsured: false,
          isCreateInsuredModal: true,
          isEdit: true,
        },
      },
      reinsured: {
        component: 'EDIT_PRODUCTS_INSURED',
        title: 'products.admin.reInsureds.edit',
        props: {
          id: value?.value,
          reInsured: true,
          isCreateInsuredModal: true,
          isEdit: true,
        },
      },
      additionalInsureds: {
        component: 'EDIT_PRODUCTS_INSURED',
        title: 'products.admin.insureds.edit',
        props: {
          id: value?.value,
          isCreateInsuredModal: true,
          isEdit: false,
        },
      },
    };
    const config = configs[type];
    return getModalConfig(config.component, config.title, config.props);
  };

  const getCreateConfig = (type) => {
    const configs = {
      client: {
        component: 'ADD_PRODUCTS_CLIENT',
        title: 'risks.createdNewClient',
        props: {
          isCreateClientModal: true,
          productCode,
        },
      },
      insured: {
        component: 'ADD_INSURED',
        title: 'risks.createdNewInsured',
        props: { productCode },
      },
      reinsured: {
        component: 'ADD_INSURED',
        title: 'risks.createdNewReInsured',
        props: {
          reInsured: true,
          productCode,
        },
      },
      additionalInsureds: {
        component: 'ADD_INSURED',
        title: 'risks.createdNewInsured',
        props: { productCode },
      },
    };

    const config = configs[type];

    return getModalConfig(config.component, config.title, config.props);
  };

  const handleCreate = {
    clientId: () => {
      dispatch(showModal(hasValueSet ? getEditConfig('client') : getCreateConfig('client')));
    },
    insuredId: () => {
      dispatch(showModal(hasValueSet ? getEditConfig('insured') : getCreateConfig('insured')));
    },
    reinsuredId: () => {
      dispatch(showModal(hasValueSet ? getEditConfig('reinsured') : getCreateConfig('reinsured')));
    },
    additionalInsureds: () => {
      dispatch(showModal(getCreateConfig('additionalInsureds')));
    },
  };

  const showOnlyCreate = (type) => {
    if (type === 'additionalInsureds') {
      return true;
    }

    return false;
  };

  const icon = userIsProducer && targetField === 'client' ? (hasValueSet ? VisibilityIcon : null) : hasValueSet ? VisibilityIcon : Add;
  const hideButton =
    userIsProducer &&
    ((targetField === 'client' && !hasValueSet) ||
      ((targetField === 'insured' || targetField === 'reinsured') && (!partyClient || isEmpty(partyClient))));
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <span>{label}</span>
      {!hideButton ? (
        <Button
          icon={showOnlyCreate(name) ? Add : icon}
          size="xsmall"
          text={hasValueSet && !showOnlyCreate(name) ? `View` : `Add new`}
          variant="contained"
          color="primary"
          onClick={() => utils.generic.isFunction(handleCreate[name]) && handleCreate[name]()}
        />
      ) : (
        <Button size="xsmall" text="nil" style={{ visibility: 'hidden' }} />
      )}
    </div>
  );
};

export default LabelAndCreate;
