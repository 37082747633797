import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

// app
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { Empty } from 'components';
import { AddProductsClientView } from './AddProductsClient.view';
import { productAdminSchema } from 'schemas';
import { useGetParentOfficeListAll, useGetProductsProgrammes } from 'lib/admin';
import { useGetRiskCountries, usePostClient, RISK_COUNTRIES } from 'lib/quoteBind';

import * as utils from 'utils';

AddProductsClient.propTypes = {
  fields: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  isAddRiskForm: PropTypes.bool,
};

AddProductsClient.defaultProps = {
  handleClose: () => {},
  productsAdmin: true,
  isAddRiskForm: false,
};

export default function AddProductsClient({
  fields,
  productCode,
  submitHandler,
  cancelHandler,
  handleClose,
  productsAdmin,
  isAddRiskForm,
}) {
  const { data: countries } = useGetRiskCountries(RISK_COUNTRIES);
  const { data: officeList } = useGetParentOfficeListAll();
  const { data: programmesProducts } = useGetProductsProgrammes();
  const { mutateAsync: postClient } = usePostClient();

  const options = useMemo(
    () => ({ countries, clientOffices: officeList, products: programmesProducts }),
    [countries, officeList, programmesProducts]
  );

  const hydrateOptions = (fields, isAddRiskForm) => {
    if (!fields) return [];

    return fields.map((field) => {
      const fieldOptions = field?.optionsDynamicKey
        ? utils.form.getSelectOptions(field.optionsDynamicKey, {
            [field.optionsDynamicKey]: options[field.optionsDynamicKey],
          })
        : field?.options
        ? field.options.map((option) => ({ ...option, label: utils.string.t(option.label) }))
        : [];

      return {
        ...field,
        label: utils.string.t(field.label),
        ...(isAddRiskForm && field.name === 'name' && { gridSize: { xs: 12, sm: 12 } }),
        ...(isAddRiskForm && { type: field?.hideInAddRiskForm ? 'hidden' : field.type }),
        ...(isAddRiskForm && field.name === 'productCode' && field?.hideInAddRiskForm && { value: productCode }),
        options: fieldOptions,
        ...(field?.optionsDefaultValue && {
          value: fieldOptions?.find((option) => option.default === true),
        }),
      };
    });
  };

  const handleSubmit = async (values) => {
    console.log(values);
    const response = await postClient({ body: values, isCreateClientModal: true, isEdit: false });
    const { data } = response?.result || {};

    if (data && utils.generic.isFunction(submitHandler)) {
      if (utils.generic.isFunction(handleClose)) {
        handleClose();
      }
      return submitHandler(data);
    }

    // fail
    handleCancel();
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(cancelHandler)) {
      cancelHandler();
    }
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const actions = [
    {
      name: 'submit',
      label: productsAdmin ? utils.string.t('products.admin.clients.create') : utils.string.t('app.submit'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  const hasFields = utils.generic.isValidArray(fields, true);

  const { fields: schemaFields } = productAdminSchema.getSchema('clients');

  const fieldsArray = hasFields ? [hydrateOptions(fields, isAddRiskForm)] : [hydrateOptions(schemaFields, isAddRiskForm)];

  const hasArrayFields = utils.generic.isValidArray(fieldsArray, true);
  const checkClientOffices = isAddRiskForm ? false : utils.generic.isInvalidOrEmptyArray(options?.clientOffices);

  // abort
  if (
    !hasArrayFields ||
    utils.generic.isInvalidOrEmptyArray(options?.countries) ||
    checkClientOffices ||
    utils.generic.isInvalidOrEmptyArray(options?.products)
  ) {
    return <Empty height={400} title={utils.string.t('products.loading')} icon={<Loading />} padding />;
  }

  return (
    <AddProductsClientView
      fields={fieldsArray}
      actions={actions}
      defaultValues={utils.form.getInitialValues(fieldsArray)}
      validationSchema={utils.form.getValidationSchema(fieldsArray)}
    />
  );
}
