import { useMemo } from 'react';
import {
  QB_RISK_COVERAGE_DEFINITION,
  QB_RISK_DEFINITION,
  INSUREDS,
  LARGE_SIZE,
  QB_CLIENTS,
  REINSUREDS,
  useGetCoverageDefinition,
  useGetRiskDefinitions,
  useGetClients,
  useGetInsureds,
  useGetReInsureds,
} from 'lib/quoteBind';

import * as utils from 'utils';

export const useFieldDefinitions = ({ productCode }) => {
  const { data: riskDefinitionData, isLoading: isDefinitionLoading } = useGetRiskDefinitions(QB_RISK_DEFINITION, productCode, {
    enabled: Boolean(productCode),
    refetchOnMount: false,
  });

  const { data: clientsContent, isLoading: isClientsLoading } = useGetClients(QB_CLIENTS, { size: LARGE_SIZE, productCode });
  const { data: insuredsContent, isLoading: isInsuredsLoading } = useGetInsureds(INSUREDS, { size: LARGE_SIZE, productCode });
  const { data: reinsuredsContent, isLoading: isReinsuredLoading } = useGetReInsureds(REINSUREDS, { size: LARGE_SIZE, productCode });

  const { data: coverageDefinitionData } = useGetCoverageDefinition(QB_RISK_COVERAGE_DEFINITION, productCode, 'COVERAGE_COMPARISON', {
    enabled: Boolean(productCode),
  });

  const definitionsFields = useMemo(() => {
    const clients = clientsContent?.content || [];
    const insureds = insuredsContent?.content || [];
    const reinsureds = reinsuredsContent?.content || [];
    const riskDefinitionsFields = riskDefinitionData?.data?.product || [];
    const riskFieldOptionsByType = riskDefinitionData?.data?.fieldOptions || [];

    return utils.risk.parseFields(riskDefinitionsFields, {
      insureds,
      reinsureds,
      clients,
      ...riskFieldOptionsByType,
    });
  }, [clientsContent?.content, insuredsContent?.content, reinsuredsContent?.content, riskDefinitionData?.data]);

  const coverageDefinition = utils.risk.parseFields(coverageDefinitionData?.data?.product || []);
  const isLoading = isDefinitionLoading || isClientsLoading || isReinsuredLoading || isInsuredsLoading;
  return { definitionsFields, coverageDefinition, isLoading };
};
