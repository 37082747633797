import get from 'lodash/get';

// mui
import { Box, makeStyles, Typography, Divider } from '@material-ui/core';
import ClassIcon from '@material-ui/icons/Class';
import EventIcon from '@material-ui/icons/Event';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TodayIcon from '@material-ui/icons/Today';

import { Breadcrumb, FormGrid, Info, Layout, PopoverMenu, Restricted, Skeleton, Tooltip, Translate } from 'components';
import {
  RiskData,
  RiskDocuments,
  RiskIssues,
  RiskMap,
  RiskPolicy,
  RiskPolicyCancelled,
  RiskPolicyExpired,
  RiskQuotes,
} from 'modules/QuoteBind';

import {
  RISK_ISSUE_SANCTIONS,
  RISK_QUOTE_STATUS_QUOTING,
  RISK_STATUS_BLOCKED,
  RISK_STATUS_BOUND,
  RISK_STATUS_CANCELLED,
  RISK_STATUS_EXPIRED,
  ROLE_BROKER,
  ROLE_PRODUCER,
} from 'consts';
import config from 'config';
import * as utils from 'utils';

// app
import styles from './RiskDetails.styles';

const RiskDetailsView = ({
  breadcrumbs,
  risk,
  riskProducts,
  riskIsLoading,
  countries,
  groups,
  definitionsFields,
  coverageDefinition,
  locations,
  locationDefinitions,
  locationKey,
  handleReQuoteRisk,
}) => {
  const classes = makeStyles(styles, { name: 'RiskDetail' })();
  const riskStatus = (risk?.riskStatus && risk?.riskStatus.toLowerCase()) || RISK_QUOTE_STATUS_QUOTING.toLowerCase();
  const { insured, reinsured, client: producer, additionalInsureds } = risk?.risk || {};

  const typeNewOrRenewal = risk?.type === 'NEW' || risk?.newRenewal === 'NEW' ? utils.string.t('app.new') : utils.string.t('app.renewal');
  const partyTypeOptions = ['BUSINESS', 'INDIVIDUAL'];
  const partyTypeValue = get(risk, 'risk.insured.partyType', '');
  const partyType = partyTypeOptions.includes(partyTypeValue) ? partyTypeValue.toLowerCase() : '';
  const reInsuredPartyTypeValue = get(risk, 'risk.reinsured.partyType', '');
  const reInsuredParty = partyTypeOptions.includes(reInsuredPartyTypeValue) ? reInsuredPartyTypeValue.toLowerCase() : '';
  const isRiskBound = risk?.riskStatus === RISK_STATUS_BOUND;
  const isCancelled = risk?.riskStatus === RISK_STATUS_CANCELLED;
  const isExpired = risk?.riskStatus === RISK_STATUS_EXPIRED;
  const isRiskSanctionsBlocked =
    risk?.riskStatus === RISK_STATUS_BLOCKED && risk?.issues?.some((issue) => RISK_ISSUE_SANCTIONS.includes(issue.issueType));

  const valuesByID = {
    clientId: risk?.risk?.client,
    insuredId: risk?.risk?.insured,
    reinsuredId: risk?.risk?.reinsured,
  };
  const insuredDetails = (
    <Box className={classes.party} component="div" mb="-6px" style={{ maxWidth: 200 }}>
      <Typography variant="h3" style={{ fontWeight: 600 }}>
        {get(insured, 'name', '')}
      </Typography>
      <Box>{utils.risk.getInsuredAddress(insured, countries)}</Box>
      <Typography variant="h4" style={{ fontWeight: 500 }}>
        {partyType && utils.string.t(`products.admin.insureds.typeOptions.${partyType}`)}
      </Typography>
    </Box>
  );

  const reInsuredDetails = (
    <>
      {reinsured ? (
        <Box className={classes.party} component="div" mb="-6px" style={{ maxWidth: 200 }}>
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            {get(reinsured, 'name', '')}
          </Typography>
          <Box>{utils.risk.getInsuredAddress(reinsured, countries)}</Box>
          <Typography variant="h4" style={{ fontWeight: 500 }}>
            {reInsuredParty && utils.string.t(`products.admin.insureds.typeOptions.${reInsuredParty}`)}
          </Typography>
        </Box>
      ) : null}
    </>
  );

  const additionalInsuredsDetails = (
    <>
      {additionalInsureds?.length > 0
        ? additionalInsureds.map((additionalInsured, index) => (
            <Box className={classes.party} component="div" mb="-6px" style={{ maxWidth: 200 }}>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                {get(additionalInsured, 'name', '')}
              </Typography>
              <Box>{utils.risk.getInsuredAddress(additionalInsured, countries)}</Box>
              <Typography variant="h4" style={{ fontWeight: 500 }}>
                {additionalInsured.partyType &&
                  utils.string.t(`products.admin.insureds.typeOptions.${additionalInsured.partyType?.toLowerCase()}`)}
              </Typography>
              {index < additionalInsureds.length - 1 && <Divider style={{ margin: '0px 0 10px 0' }} />}
            </Box>
          ))
        : null}
    </>
  );

  return (
    <Box className={classes.pageContainer}>
      <Layout testid="risk" isCentered>
        <Layout main padding={false}>
          {riskIsLoading ? (
            <div className={classes.riskInfoRoot} data-testid="riskDetail}">
              <div className={classes.header} style={{ paddingLeft: 20 }}>
                <Skeleton height={50} width={400} displayNumber={1} />
              </div>
              <div className={classes.riskInfoContainer}>
                <div className={classes.riskInfo}>
                  <div className={classes.info}>
                    <FormGrid container>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                    </FormGrid>
                  </div>
                  <div className={classes.info}>
                    <FormGrid container>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Skeleton height={40} displayNumber={1} />
                      </FormGrid>
                    </FormGrid>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.riskInfoRoot} data-testid="riskDetail}">
              <div className={classes.header}>
                <Breadcrumb links={breadcrumbs} />
                {isRiskSanctionsBlocked ? null : (
                  <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                    <div className={classes.riskMenu}>
                      <PopoverMenu
                        id="risk-quote-menu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        items={[
                          {
                            id: 1,
                            label: utils.string.t('app.duplicate'),
                            callback: () => handleReQuoteRisk(true),
                          },
                        ]}
                      />
                    </div>
                  </Restricted>
                )}
              </div>
              <div className={classes.riskInfoContainer}>
                <div className={classes.riskInfo}>
                  <div className={classes.info}>
                    <FormGrid container>
                      {insured?.name ? (
                        <FormGrid item xs={12} sm={6} md={3}>
                          <Tooltip rich placement="bottom" title={insuredDetails}>
                            <Info
                              title={utils.string.t('app.insured')}
                              avatarText={insured?.name[0]}
                              description={insured?.name}
                              data-testid="risk-summary-insured"
                            />
                          </Tooltip>
                        </FormGrid>
                      ) : null}
                      {additionalInsureds?.length > 0 ? (
                        <FormGrid item xs={12} sm={6} md={3}>
                          <Tooltip rich placement="bottom" title={additionalInsuredsDetails}>
                            <Info
                              title={utils.string.t(
                                additionalInsureds?.length > 1 ? 'app.additionalInsured_plural' : 'app.additionalInsured'
                              )}
                              avatarText={`+${additionalInsureds?.length}`}
                              description={additionalInsureds?.map((item) => item?.name).join(', ')}
                              data-testid="risk-summary-additionalInsured"
                            />
                          </Tooltip>
                        </FormGrid>
                      ) : null}
                      {reinsured?.name ? (
                        <FormGrid item xs={12} sm={6} md={3}>
                          <Tooltip rich placement="bottom" title={reInsuredDetails}>
                            <Info
                              title={utils.string.t('app.reInsured')}
                              avatarText={reinsured?.name[0]}
                              description={reinsured?.name}
                              data-testid="risk-summary-reinsured"
                            />
                          </Tooltip>
                        </FormGrid>
                      ) : null}
                      {producer?.name ? (
                        <FormGrid item xs={12} sm={6} md={3}>
                          <Info
                            title={utils.string.t('app.producer')}
                            avatarText={producer?.name[0]}
                            description={producer?.name}
                            data-testid="risk-summary-producer"
                          />
                        </FormGrid>
                      ) : null}
                    </FormGrid>
                  </div>
                  <div className={classes.info}>
                    <FormGrid container>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Info
                          title={utils.string.t('app.product')}
                          avatarIcon={LocalOfferIcon}
                          avatarImage={`${config.assets}/quote-bind/${risk?.riskType}.svg`}
                          description={utils.risk.getRiskName(risk?.riskType, riskProducts)}
                          nestedClasses={{
                            avatar: classes.typeIcon,
                          }}
                          data-testid="risk-summary-type"
                        />
                      </FormGrid>
                      <FormGrid item xs={12} sm={6} md={3}>
                        <Info
                          title={utils.string.t('app.type')}
                          avatarIcon={ClassIcon}
                          description={typeNewOrRenewal}
                          data-testid="risk-summary-new-renewal"
                        />
                      </FormGrid>
                      {!isRiskBound ? (
                        <>
                          <FormGrid item xs={12} sm={6} md={3}>
                            <Info
                              title={utils.string.t('app.inceptionDate')}
                              avatarIcon={TodayIcon}
                              description={
                                <Translate label="format.date" options={{ value: { date: risk?.inceptionDate, default: '-' } }} />
                              }
                              data-testid="risk-summary-inception-date"
                            />
                          </FormGrid>
                          <FormGrid item xs={12} sm={6} md={3}>
                            <Info
                              title={utils.string.t('app.expiryDate')}
                              avatarIcon={EventIcon}
                              description={<Translate label="format.date" options={{ value: { date: risk?.expiryDate, default: '-' } }} />}
                              data-testid="risk-summary-expiry-date"
                            />
                          </FormGrid>
                        </>
                      ) : null}
                    </FormGrid>
                  </div>
                </div>
              </div>
            </div>
          )}
          {riskIsLoading ? (
            <div className={classes.root} style={{ minHeight: 200 }}>
              <div className={classes.riskInfoRoot}>
                <div className={classes.header} style={{ paddingLeft: 20 }}>
                  <Skeleton height={50} width={100} displayNumber={1} />
                </div>
                <div className={classes.content}>
                  <Skeleton height={200} width={300} displayNumber={1} />
                </div>
              </div>
            </div>
          ) : (
            <>
              {isRiskSanctionsBlocked ? (
                <Box className={classes.quotesContainer}>
                  <RiskIssues risk={risk} riskStatus={riskStatus} riskIsLoading={riskIsLoading} data-testid="RiskIssues" />
                </Box>
              ) : isRiskBound ? (
                <RiskPolicy risk={risk} riskIsLoading={riskIsLoading} />
              ) : isCancelled ? (
                <Box className={classes.quotesContainer} data-testid="RiskPolicyCancelled">
                  <RiskPolicyCancelled risk={risk} riskIsLoading={riskIsLoading} />
                </Box>
              ) : isExpired ? (
                <Box className={classes.quotesContainer} data-testid="RiskPolicyExpired">
                  <RiskPolicyExpired risk={risk} riskIsLoading={riskIsLoading} />
                </Box>
              ) : (
                <Box mb={3}>
                  <RiskQuotes
                    risk={risk}
                    riskStatus={riskStatus}
                    coverageDefinition={coverageDefinition}
                    riskIsLoading={riskIsLoading}
                    handleReQuoteRisk={handleReQuoteRisk}
                    data-testid="RiskQuotes"
                  />
                </Box>
              )}
              {utils.generic.isValidArray(locations, true) ? (
                <Box mb={3}>
                  <RiskMap title={locationDefinitions?.displayTableTitle} locations={locations} locationDefinitions={locationDefinitions} />
                </Box>
              ) : null}
              {risk?.documents && risk?.id ? (
                <Box mb={3}>
                  <RiskDocuments riskId={risk?.id} />
                </Box>
              ) : null}
              <Box className={classes.quotesContainer}>
                <RiskData
                  riskIsLoading={riskIsLoading}
                  riskValues={risk?.risk}
                  groups={groups}
                  definitionsFields={definitionsFields}
                  valuesByID={valuesByID}
                  locationKey={locationKey}
                />
              </Box>
            </>
          )}
        </Layout>
      </Layout>
    </Box>
  );
};

export default RiskDetailsView;
