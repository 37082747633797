import { useEffect, useState, createContext } from 'react';
import isEmpty from 'lodash/isEmpty';

import { usePatchEndorsement, usePatchRisk, usePostDraftRisk, usePostEndorsement, usePostRisk } from 'lib/quoteBind';

import { useFieldDefinitions } from 'pages/QuoteBind/hooks/useFieldDefinitions';
import { RISK_ENDORSEMENT_FIELDS } from 'consts/quote-bind';

// app
import * as utils from 'utils';

import MultiStepForm from './MultiStepForm';
import { MultiStepLoading } from './MultiStepLoading';

const INITIAL_RISK_CONTEXT = { risk: null, fieldDefinition: null };

export const RiskContext = createContext(INITIAL_RISK_CONTEXT);

const AddEditQuoteBind = ({
  fullScreen,
  product,
  riskData,
  draftId,
  riskId,
  effectiveDate = null,
  duplicate = false,
  addEndorsement = false,
  renewal = false,
  endorsementId,
}) => {
  const type = product?.value;
  const { mutateAsync: postRisk } = usePostRisk();
  const { mutateAsync: patchRiskData } = usePatchRisk();
  const { mutateAsync: postRiskDraft } = usePostDraftRisk();
  const { mutateAsync: postEndorsement } = usePostEndorsement();
  const { mutateAsync: patchEndorsement } = usePatchEndorsement();

  const [isLoading, setIsLoading] = useState(true);

  const isReQuote = Boolean(riskId) && !duplicate;
  const isEndorsement = Boolean(riskId) && (addEndorsement || endorsementId);
  const isRenewal = Boolean(riskId) && renewal;
  const { definitionsFields, isLoading: isRiskdefinitionLoading } = useFieldDefinitions({ productCode: type });

  const riskDefinitionsFields = (() => {
    if (isEndorsement) {
      const endorsementDefinitionFields = [...definitionsFields, ...RISK_ENDORSEMENT_FIELDS];
      return utils.risk.disableInceptionExpiry(endorsementDefinitionFields);
    }
    if (isRenewal) {
      return utils.risk.disableInceptionExpiryRenewal(definitionsFields);
    }
    return definitionsFields || [];
  })();

  useEffect(() => {
    let isSubscribed = true;

    const isLoadingValue = isRiskdefinitionLoading;
    isSubscribed && setIsLoading(isLoadingValue);

    return () => (isSubscribed = false);
  }, [isRiskdefinitionLoading]);

  const handlePostRisk = (values) => {
    if (isEndorsement) {
      return endorsementId
        ? patchEndorsement({ definitions: riskDefinitionsFields, values, riskId, riskData, effectiveDate, endorsementId })
        : postEndorsement({ definitions: riskDefinitionsFields, values, riskId, riskData, effectiveDate });
    }

    return isReQuote
      ? patchRiskData({ values, type, definitions: riskDefinitionsFields, riskId, riskData })
      : postRisk({ values, type, definitions: riskDefinitionsFields, draftId });
  };

  const handlePostDraftRisk = (values) => {
    draftId
      ? postRiskDraft({ values, type, definitions: riskDefinitionsFields, draftId })
      : postRiskDraft({ values, type, definitions: riskDefinitionsFields });
  };

  const defaultValues = riskData
    ? utils.form.getFormattedValues(riskData, riskDefinitionsFields)
    : utils.form.getInitialValues(riskDefinitionsFields);

  if (isLoading || isEmpty(defaultValues)) {
    return <MultiStepLoading />;
  }

  return (
    <RiskContext.Provider value={{ risk: { ...riskData, riskType: type }, fieldDefinition: riskDefinitionsFields }}>
      <MultiStepForm
        isReQuote={isReQuote}
        isEndorsement={isEndorsement}
        fullScreen={fullScreen}
        productType={type}
        fields={riskDefinitionsFields}
        defaultValues={defaultValues}
        riskDataValues={riskData}
        handleSubmit={handlePostRisk}
        handleDraftSave={handlePostDraftRisk}
      />
    </RiskContext.Provider>
  );
};

export default AddEditQuoteBind;
