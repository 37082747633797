import React, { FC } from 'react';

import { makeStyles, Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wordWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface IBaseTableCellProps {
  cellData?: string;
  style?: React.CSSProperties;
}

export const BaseTableCell: FC<IBaseTableCellProps> = ({ cellData, style }) => {
  const classes = useStyles();

  return (
    <Tooltip title={cellData ?? ''} placement="top-start">
      <Typography className={classes.wordWrap} style={style}>
        {cellData}
      </Typography>
    </Tooltip>
  );
};
